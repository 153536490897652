import React from 'react';
import { Link } from 'react-router-dom';

const Civil = () => {
    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>

                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Génie Civil</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li><Link to="/activity">Activités</Link></li>
                            <li>Génie Civil</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                {/* ======= About Section ======= */}
                <section id="civil" className="civil">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card " >
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">GENIE CIVIL</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Etudes de conception</h6>
                                            <p>La phase d’avant-projet des études de conception est un moment clé de l’opération. En effet, à l’approbation de cette phase par le maître d’ouvrage, le programme, le coût des travaux, ainsi que le forfait de rémunération de la maîtrise d’œuvre sont arrêtés de manière définitive.</p>
                                            <h6 className="fw-bold">Dimensionnement d’ouvrage</h6>
                                            <p>Pour vos projets de voirie, d’assainissement, nous faisons le dimensionnement en respectant les aspects techniques, économiques ou réglementaires.</p>
                                            <h6 className="fw-bold">Contrôle qualité et Assistance technique</h6>
                                            <p>Nos ingénieurs et techniciens sont en mesure, dans le cadre de missions spécifiques d’assistance technique à la prise de décision, de formuler des avis ponctuels sur la conformité des projets qui leurs sont soumis.</p>
                                            <p>Ils peuvent également procéder au contrôle qualité qui permettra de déterminer, avec des moyens appropriés, la conformité ou non des spécifications ou exigences préétablies.</p>
                                            <h6 className="fw-bold">Maitrise d’œuvre – OPC</h6>
                                            <p>La mission OPC assure la liaison et la coordination entre le Maître d’Œuvre, les entreprises, le Bureau de contrôle et, d’une manière générale, l’ensemble des intervenants à la réalisation de l’ouvrage Nous établissons le calendrier d’exécution et coordonnons l’avancement des travaux pour faire respecter le délai global prescrit dans le marché et de permettre la livraison des ouvrages conformes aux prescriptions.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">ROUTES & GEOTECHNIQUE</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Conception routière</h6>
                                            <p>La conception géométrique des routes permet d’assurer des conditions de visibilité satisfaisantes tant au droit des points singuliers qu’en section courante. Une de nos taches est de rechercher un juste équilibre entre les besoins en visibilité et les contraintes spécifiques au projet.</p>
                                            <h6 className="fw-bold">Dimensionnement de fondations</h6>
                                            <p>La conception, le dimensionnement et la réalisation des fondations superficielles font partie des aspects traditionnels et courants de l’ingénierie géotechnique. Outre l’expérience et le jugement géotechnique requis, le recours aux principes de conception et aux méthodes de calcul modernes des fondations est indispensable en vue de satisfaire aussi bien le critère de sécurité de fonctionnement de l’ouvrage que celui de l’économie de réalisation.</p>
                                            <h6 className="fw-bold">Conception VRD</h6>
                                            <p>Faire confiance à SAHEL GEO pour réaliser vos projets VRD vous certifie qualité et respect des normes. Nous garantissons une étude complète de votre projet ainsi qu’une communication fiable et précise dans tous nos travaux.</p>
                                            <h6 className="fw-bold">Ouvrages de soutènement</h6>
                                            <p>Nous pratiquons avec brio les ouvrages de soutènement qui sont conçus pour créer une dénivelée entre les terres situées à l’amont de l’ouvrage, c’est-à-dire soutenues par celui-ci, et les terres situées à l’aval, devant l’ouvrage.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>




            </main>{/* End #main */}


        </>
    );
};

export default Civil;