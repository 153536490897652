import axios from 'axios'
import { base_url, config, instance } from "../../utils/axiosconfig"

const createBlog = async (data) => {
    try {
        const response = await instance.post(`${base_url}blog`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}


const getBlog = async () => {
    try {
        const response = await axios.get(`${base_url}blog`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getBlogById = async (id) => {
    try {
        const response = await axios.get(`${base_url}blog/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const updateBlog = async (data) => {
    try {
        const response = await instance.put(`${base_url}blog/${data.id}`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const deleteBlog = async (id) => {
    try {
        const response = await instance.delete(`${base_url}blog/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getFilteredBlogs = async (data) => {
    try {
        const response = await axios.get(`${base_url}blog/filtered-blog`, { params: data }, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const blogService = {
    getBlog,
    getBlogById,
    createBlog,
    updateBlog,
    deleteBlog,
    getFilteredBlogs
}