import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { faqService } from './faqService';

export const getAllFaq = createAsyncThunk(
    'faq/getAllFaq',
    async () => {
        const response = await faqService.getFaq()
        return response
    }
)

export const getFaqById = createAsyncThunk(
    'faq/getFaqById',
    async (id) => {
        const response = await faqService.getFaqById(id)
        return response
    }
)

export const addFaq = createAsyncThunk(
    'faq/addFaq',
    async (data) => {
        const response = await faqService.createFaq(data)
        return response
    }
)

export const updateFaq = createAsyncThunk(
    'faq/updateFaq',
    async (data) => {
        const response = await faqService.updateFaq(data)
        return response
    }
)

export const deleteFaq = createAsyncThunk(
    'faq/deleteFaq',
    async (id) => {
        const response = await faqService.deleteFaq(id)
        return response
    }
)

const initialState = {
    faqs: [],
    faq: {},
    status: 'idle',
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
}

export const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllFaq.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.faqs = action.payload
            })
            .addCase(getAllFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
            })
            .addCase(getFaqById.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFaqById.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.faq = action.payload
            })
            .addCase(getFaqById.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
            })
            .addCase(addFaq.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.faqs.push(action.payload)
                toast.success('Faq added successfully')
            })
            .addCase(addFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
                toast.error('Failed to add faq')
            })
            .addCase(updateFaq.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                const index = state.faqs.findIndex(faq => faq.id === action.payload.id)
                state.faqs[index] = action.payload
                toast.success('Faq updated successfully')
            })
            .addCase(updateFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
                toast.error('Failed to update faq')
            }
            )
            .addCase(deleteFaq.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteFaq.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.faqs = state.faqs.filter(faq => faq.id !== action.payload.id)
                toast.success('Faq deleted successfully')
            })
            .addCase(deleteFaq.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
                toast.error('Failed to delete faq')
            })
    }
})

export default faqSlice.reducer