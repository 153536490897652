import React from 'react';
import { Link } from 'react-router-dom';

const Formation = () => {
    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>

                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Formation</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li><Link to="/activity">Activités</Link></li>
                            <li>Formation</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                {/* ======= About Section ======= */}
                <section id="formation" className="formation">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">

                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">Séminaires</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Au cours de ces séminaires seront parcourues les bases générales théoriques de la géomatique ainsi que la pratique de ses outils. Les participants seront formés à recueillir, stocker, analyser et diffuser une quantité importante de données thématiques en utilisant le potentiel que leur offrent les outils de la géomatique.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">Formation aux logiciels d’ingénierie</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Nous offrons des formations sur tout ce qui est logiciel de géométrie mais aussi de génie civil à savoir :</p>
                                            <ul>
                                                <li>COVADIS</li>
                                                <li>AUTOCAD</li>
                                                <li>MENSURA</li>
                                                <li>ROBOT</li>
                                                <li>GRAITEC</li>
                                                <li>ARCHICARD</li>
                                                <li>Etc.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">Formations à la carte</h4>
                                        </div>
                                        <div className="card-body">
                                            <p>Les formations à la carte sont organisées à la demande des entreprises. Le contenu et la durée des formations sont établis en fonction du cahier de charge soumis et tiennent compte du niveau des clients.</p>
                                            <p>Une partie de la formation peut se dérouler sur le site de l’entreprise concerné, afin de prendre en compte ses réalités.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>



            </main>{/* End #main */}


        </>
    );
};

export default Formation;