export const references = [
    {
      title: "SOCOCIM INDUSTRIES",
      year: "2021-2013",
      text: "Travaux topographiques mensuels de modélisation de stocks dans la carrière de Sococim et évaluation des volumes des stocks à partir de modèle numérique de terrain (MNT) comprenant",
      listItems: [
        "Evaluation des tas de (clinker, sable, charbons, gypse, latérite, laitier, cendre, vieux phosphogypse, coque d’arachides, calcaires)",
        "Rédaction et fourniture de rapport"
      ]
    },
    {
      title: "PRODAC / SIAT : DOMAINES AGRICOLES COMMUNAUTAIRE DE BOULEL",
      year: "2020",
      text: "Relevés topographiques du domaine agricole de Boulel d’une superficie de 1910 Hectares y compris piste d’exploitation de 30 Km comprenant",
      listItems: [
        "Mise en place de canevas de référence",
        "Relevés planimétrique et altimétrique d’état des lieux",
        "Levers de détails des pistes d’accès y compris réalisation des profils",
        "Etablissement et fourniture de plan d’étude et rapport topographique"
      ]
    },
    {
      title: "PACK AFRICA",
      year: "2020",
      text: "Relevés topographiques et établissement de plans constitutifs de dossier de déclaration d’établissement classé auprès du ministère de l’environnement comprenant",
      listItems: [
        "Mise en place de canevas de référence",
        "Relevés planimétriques d’état des lieux",
        "Etablissement et fourniture de plans de situation, plan de masse et plan d’installation"
      ]
    },
    {
        title: "PARTICULIER",
        year: "2020",
        text: "Etude photogrammétrique par drone comprenant",
        listItems: [
          "Relevé photogrammétrique par drone",
          "Etablissement de plan d’étude sur format normalisé avec représentation de tous les détails pertinents (voirie bâtiments, dallages, réseau de drainage, terrain naturel)",
          "Fourniture de l’ortho-mosaïque"
        ]
      },
      {
        title: "SECAA (Société Eiffage de la Concession de l’Autoroute de l’Avenir)",
        year: "2020",
        text: "Relevés topographiques d’étude de l’autoroute à Péage comprenant",
        listItems: [
          "Mise en place de référentiel",
          "Relevés planimétrique et altimétrique des ouvrages des drainages des eaux pluviales (Patte d’oie)",
          "Etablissement de plan d’étude sur format normalisé avec représentation du carroyage et de tous les détails pertinents (voirie, bâtiments, dallages, réseau de drainage, terrain naturel)",
          "Canevas de base rattachée au RRS et au NGS",
          "Fourniture de rapport incluant le plan à l’échelle 1/200 et les fiches signalétiques des points de référence"
        ]
      },
      {
        title: "DFA IMMO",
        year: "2020",
        text: "Travaux topographiques sur un immeuble R+7 avec sous sol sis à Sacré Cœur 1 d’une superficie d’environ 800 m² comprenant",
        listItems: [
          "La réalisation du plan de récolement de l’immeuble",
          "La réalisation des travaux de division en copropriété d’immeuble y compris : Mise en place du plan foncier, Etablissement des plans de divisions, Etablissement de l’état descriptif",
          "La vérification des hauteurs sous plafond des différents niveaux"
        ]
      },
      {
        title: "GECAMINES – SODEVIT S.A",
        year: "2018-2015",
        text: "Evaluation de stocks de matériaux dans les carrières (Diack et Bandia) et les dépôts (Cambérène, Pikine, Patte d’Oie, Rufisque, Keur Daouda SARR, Thiès, Gandigal, Kaolack, et Louga) de Gécamine et Sodevit."
      },
      {
        title: "La Rochette",
        year: "2018",
        text: "Relevés topographiques d’étude de l’usine la Rochette comprenant",
        listItems: [
          "Vérification géométrique de l’axe principal de la fondation",
          "Vérification géométrique des axes secondaires de la fondation",
          "Travaux complémentaires de mise en place de repères de nivellement"
        ]
      },
      {
        title: "IMMOLAND SARL",
        year: "2018",
        text: "Relevés topographiques sur un terrain situé sur la corniche Ouest de Dakar d’une superficie d’environ 1500 m² comprenant",
        listItems: [
          "Mise en place de canevas de référence",
          "Relevés planimétrique et altimétrique d’état des lieux",
          "Etablissement et fourniture de plan d’étude"
        ]
      },
      {
        title: "EFOR SARL",
        year: "2018-2017",
        text: "Travaux de supervision et de contrôle topographique des travaux de construction de la centrale électrique de Kolda comprenant",
        listItems: [
          "Mise en place de canevas de référence",
          "Relevés planimétrique et altimétrique d’état des lieux",
          "Etablissement et fourniture de plan de terrassement zone massif",
          "Implantation des massifs de supports électriques, des réacteurs et des transformateurs",
          "Implantation des axes des voiries intérieures",
          "Réception topographique des tiges d’ancrages et ouvrages Génie civil"
        ]
      },
      {
        title: "Petowal Mining Company SA (PMC SA)",
        year: "2017",
        text: "Travaux topographiques de la concession minière accordée à PMC dans la zone de Mako et comprenant",
        listItems: [
          "La reconnaissance de site, l’implantation et le piquetage de la concession",
          "La fourniture et la pose de borne",
          "L’élaboration et la fourniture d’un rapport"
        ]
      },
      {
        title: "Mako Exploration Company SA-MEC",
        year: "2017",
        text: "Travaux topographiques de bornage de concession minière dans la zone de Mako comprenant",
        listItems: [
          "Mobilisation, Reconnaissance de site, Implantation et piquetage de la concession (8720 ha)",
          "Fourniture et pose de borne",
          "Rapport"
        ]
      },
      {
        title: "Port Minéralier de Bargny",
        year: "2016",
        text: "Etudes topographiques du site du port minéralier de Bargny comprenant",
        listItems: [
          "La mise en place d’un canevas polygonale rattaché au RRS et au NGS par des points espacés de 300 mètres matérialisés par des bornes en béton",
          "Les relevés topographiques tridimensionnels du site de 484 ha conformément aux spécifications du cahier de charges",
          "L’organisation d’enquêtes complémentaires afin de collecter d’une part les informations sur les infrastructures, les VRD en cours de réalisation ou projetés auprès des sociétés concessionnaires et d’autre part tous les renseignements utiles et actuels (localisation, contenance, statut juridique, etc.) sur les terrains se trouvant dans l’emprise du port minéralier auprès des bureaux du cadastre et de l’urbanisme de Rufisque et auprès de la commune de Bargny",
          "L’établissement des plans de situation à une échelle convenable (1/5000)",
          "L’établissement des plans d’état des lieux à l’échelle 1/1000",
          "La situation foncière",
          "La fourniture d’un dossier de levé complet",
          "L’étude et le calcul des terrassements généraux y compris la fourniture d’un rapport des études de terrassement"
        ]
      },
      {
        title: "ATELIERS DU SAHEL",
        year: "2015",
        text: "Travaux topographiques, d’études et de suivis géométriques de chantiers d’ouvrages métalliques y compris",
        listItems: [
          "Suivi et contrôle géométrique des travaux de construction de la centrale électrique de Tobène",
          "Relevés topographiques d’études de 09 passerelles sur la RN1 entre Thiaroye et Keur Mbaye Fall",
          "Relevés d’études et contrôle géométrique de la construction des nouvelles passerelles de Gibraltar et Malick SY"
        ]
      },
      {
        title: "BET GAUDILLAT - COTECNA",
        year: "2014",
        text: "Travaux de construction Aire de Scannage de camions à Kidira et à Rosso :",
        listItems: [
          "Délimitation et bornage de la zone d’étude topographique",
          "Relevé d’études des sites et des alentours y compris :",
          "a – Rattachement au RRS et au NGS",
          "b – Relevés planimétriques et altimétrique de 100 à 400 pts à l’ha",
          "c – Mise en place de bornes de référence",
          "d – Fourniture du fichier Autocad du plan d’études"
        ]
      },
      {
        title: "SORED MINES S.A",
        year: "2014-2013",
        text: "Travaux topographiques de localisation points de sondage répartis sur neuf (09) zones (Korolon, Korolon sud, Dendifa, Khossanto, Konkoto, Diringhoto, Gossan, Niamia, Madina Ouest) autour du village de Khossanto y compris",
        listItems: [
          "Mise en place d’un canevas de détail rattaché aux points d’appui existant (Korolon, Dendifa et Salouma)",
          "Détermination des coordonnées planimétriques et altimétriques de chaque point de sondage",
          "Détermination de l’altitude de chaque point de sondage par nivellement direct de précision",
          "Etablissement et fourniture de plan d’état des lieux des sites et des projets de liste de coordonnées des points de sondage de chaque zone",
          "Rapport"
        ]
      },
      {
        title: "SORED MINES",
        year: "2013",
        text: "Travaux de transformation de coordonnées",
        listItems: [
          "Mis au point d’un utilitaire informatique de calcul de transformation de coordonnées UTM 28 et 29 (Adindan) en coordonnée WGS84 UTM28 étude"
        ]
      },
      {
        title: "ORYX Sénégal SA",
        year: "2013",
        text: "Relevés topographiques sur le terrain de Oryx sis à Hann plage comprenant",
        listItems: [
          "Délimitation de parcelle de 200 m²",
          "Relevés d’état des lieux de la parcelle et ses abords",
          "Rattachement au réseau de référence du Sénégal",
          "Etablissement et fourniture de plan parcellaire"
        ]
      },
      {
        title: "Institution de Prévoyance Retraite du Sénégal (IPRES)",
        year: "2012",
        text: "Travaux topographiques des TF n° 399/DK ex 17156/DG ; TF 2903/DK ex 541/DG et TF 2899/DK ex 536/DG sis à la place de l’indépendance comprenant",
        listItems: [
          "Mis au point d’un utilitaire informatique de calcul de transformation de coordonnées UTM 28 et 29 (Adindan) en coordonnée WGS84 UTM28 étude"
        ]
      },
      {
        title: "SOCAS",
        year: "2012",
        text: "Relevés topographiques complémentaire de l’usine de la SOCAS à Savoigne y compris",
        listItems: [
          "Etablissement de plan de situation échelle 1/2000",
          "Etablissement de plan de masse échelle 1/500",
          "Fourniture de plans sur support papier format A1 et sur CD"
        ]
      },
      {
        title: "BET GAUDILLAT – SAHEL IMMO",
        year: "2012",
        text: "Construction d’immeubles tertiaires sur un site sécurisé – Etudes topographiques",
        listItems: [
          "Rattachement",
          "Relevés topographiques de détails singuliers",
          "Etablissement et fourniture de plan",
          "Rapport"
        ]
      },
      {
        title: "BET GAUDILLAT - APGB",
        year: "2011",
        text: "Travaux Topographiques Dépôt des Containers PORT DE Bissau",
        listItems: [
          "Relevés topographiques",
          "Etablissement et fourniture de plan d’état des lieux avec représentation des évidences cadastrales et du relief par des courbes de niveau et des points cotés",
          "Rapport"
        ]
      },
      {
        title: "AGEROUTE / Apave",
        year: "2011",
        text: "Etudes géométriques de 100 km de route dans la région de Louga – Patmur",
        listItems: [
          "Relevés topographiques des tronçons Léona – Lompoul, Louga – Potou et Potou – Gandiole",
          "Etablissement et fourniture de plan d’état des lieux des bandes d’études ; Etudes géométriques et fournitures plans d’études (tracé en plan, profiles en long et en travers)",
          "Evaluation et fourniture des quantités de cubatures de terrassement"
        ]
      },
      {
        title: "Ministère de l’Economie et des Finances – Maître d’œuvre Direction du Cadastre",
        year: "2009",
        text: "Direction du Cadastre",
        listItems: [
          "Coordination et suivi des enquêtes cadastrales dans la région de Dakar et la zone de Mbour y compris Saly en République du Sénégal"
        ]
      },
      {
        title: "Industries Chimiques du Sénégal (ICS)",
        year: "2008",
        text: "ICS Contrôle topométrique des travaux de réhabilitation des bassins de réfrigération de la concentration du lot 2",
        listItems: [
          "Etudes et exploitation des plans",
          "Rattachements planimétriques et altimétriques au point de référence du site",
          "Réception des implantations, des niveaux, des bétons et des réservations",
          "Rapport"
        ]
      },
      {
        title: "DANGOTE Industries Sénégal",
        year: "2008",
        text: "Relevés topographiques du site de la cimenterie Dangote Industries Sénégal",
        listItems: [
          "Relevés topographiques de site de la cimenterie et du corridor reliant l’usine et la carrière",
          "Fourniture et mise en place de 04 bornes de référence",
          "Etablissement et fourniture du fichier des plans d’état des lieux et des plans fonciers du site",
          "Rapport"
        ]
      },
      {
        title: "SONES",
        year: "2005",
        text: "Etudes topographiques pour la Réhabilitation de la conduite DN 200 de Saly et Somone",
        listItems: [
          "Etudes topographiques et géométriques",
          "Plans et implantations"
        ]
      },
      {
        title: "Port Autonome de Dakar (PAD)",
        year: "2005",
        text: "Plateforme de distribution du Port Autonome de Dakar (PAD)",
        listItems: [
          "Etudes topographiques (actualisation des plans) et géométriques (évaluation des quantités de cubature des terrassements généraux, et de la voirie (routes et parkings))"
        ]
      },
      {
        title: "SOCETRA - AGETIP",
        year: "2005",
        text: "Aménagement de la 3ème tranche ZAC de Mbao. Etudes, implantation et suivi",
        listItems: [
          "Relevés et plans d’état des lieux",
          "Etudes des terrassements généraux, Suivi topographiques et géotechniques",
          "Etudes de la voirie et des ouvrages d’assainissement, Suivi topographiques et géotechniques"
        ]
      },
      {
        title: "ASECNA",
        year: "2005",
        text: "Etudes topographique aérodrome de Médina Gounass",
        listItems: [
          "Etudes topographique et géométrique"
        ]
      }
  ];
