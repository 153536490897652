import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import tagReducer from '../features/tag/tagSlice';
import blogReducer from '../features/blog/blogSlice';
import categoryReducer from '../features/category/categorySlice';
import commentReducer from '../features/comment/commentSlice';
import uploadReducer from '../features/upload/uploadSlice';
import faqReducer from '../features/faq/faqSlice';



export const store = configureStore({
    reducer: {
        user: userReducer,
        tag: tagReducer,
        blog: blogReducer,
        category: categoryReducer,
        comment: commentReducer,
        upload: uploadReducer,
        faq: faqReducer,
    },
    });