import React from 'react';
import { AdminLayout } from './AdminLayout';
import { getFaqById, updateFaq } from '../../features/faq/faqSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';


const EditFaq = () => {
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');

    const location = useLocation();

    const getFaqId = location.pathname.split('/')[3];

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateFaq({title: title, content: content, id: getFaqId}));
        setContent('');
        setTitle('');
    };

    useEffect(() => {
        const fetchTag = async () => {
            if (getFaqId) {
                const faq = await dispatch(getFaqById(getFaqId));
                setTitle(faq.payload.title);
                setContent(faq.payload.content);
            }
        };
        fetchTag();
    }, [dispatch, getFaqId]);

    return (

        <AdminLayout>
            <section className="section p-0" style={{height: "80vh"}}>
                <div className="row" style={{width: "88%", height: "100%"}}>
                    <div className="col-lg-12 me-2" style={{height: "100%"}}>
                        <div className="card" style={{height: "100%"}}>
                            <div className="card-body" style={{height: "100%"}}>
                                <h3 className="admin-card-title">Modifier une Question</h3>
                                <form onSubmit={handleSubmit} style={{height: "100%"}}>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Question</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" value={title} onChange={(e) => setTitle(e.target.value)} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Contenu</label>
                                        <div className="col-sm-10">
                                            <textarea type="text" className="form-control" value={content} onChange={(e) => setContent(e.target.value)} />
                                        </div>
                                    </div>
                                   
                                   

                                   
                                    <div className="row mb-3">
                                        <div className="col-sm-10 w-100 d-flex justify-content-end me-5">
                                            <button type="submit" className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

        </AdminLayout>
    );
};

export { EditFaq };


