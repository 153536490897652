import React from 'react';
import Testimonials from '../components/Testimonials';
import ReactCardFlip from 'react-card-flip';
import { useState } from 'react';
import { Link } from 'react-router-dom';



const Home = () => {
    const [isFlipped1, setIsFlipped1] = useState(false);
    const [isFlipped2, setIsFlipped2] = useState(false);
    const [isFlipped3, setIsFlipped3] = useState(false);
    const [isFlipped4, setIsFlipped4] = useState(false);
    const [isFlipped5, setIsFlipped5] = useState(false);
    const [isFlipped6, setIsFlipped6] = useState(false);



    const handleClick1 = () => setIsFlipped1(!isFlipped1);
    const handleClick2 = () => setIsFlipped2(!isFlipped2);
    const handleClick3 = () => setIsFlipped3(!isFlipped3);
    const handleClick4 = () => setIsFlipped4(!isFlipped4);
    const handleClick5 = () => setIsFlipped5(!isFlipped5);
    const handleClick6 = () => setIsFlipped6(!isFlipped6);

    return (
        <>
            <div>
                {/* ======= Hero Section ======= */}
                <section id="hero" className="hero">
                    <div className="info d-flex align-items-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-6 text-center">
                                    <h2 data-aos="fade-down">Bienvenue à <span> <br /> SAHEL GEOMATIQUE</span></h2>
                                    <p data-aos="fade-up">Un Cabinet Géomètre Topographe intervenant dans diverses branches de la GEOMATIQUE et du GENIE CIVIL et depuis peu dans l'Agriculture de Précision. Profitez de notre expérience, expertise et de notre parc matériel dernières technologies: Drones multi rotors & RTK, GPS Différentiels, Stations totales et robotisées </p>
                                    <a data-aos="fade-up" data-aos-delay={200} href="#alt-services" className="btn-get-started">Découvrez Nous</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="hero-carousel" className="carousel slide" data-bs-ride="carousel" data-bs-interval={5000}>
                        <div className="carousel-item active" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-1.jpg)' }} />
                        <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-2.jpg)' }} />
                        <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-3.jpg)' }} />
                        <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-4.jpg)' }} />
                        <div className="carousel-item" style={{ backgroundImage: 'url(assets/img/hero-carousel/hero-carousel-5.jpg)' }} />
                        <a className="carousel-control-prev" href="#hero-carousel" role="button" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true" />
                        </a>
                        <a className="carousel-control-next" href="#hero-carousel" role="button" data-bs-slide="next">
                            <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true" />
                        </a>
                    </div>
                    {/* ======= Alt Services Section ======= */}
                    <section id="alt-services" className="alt-services">


                        <div className="container" data-aos="fade-up">
                            <div className="row justify-content-around gy-4">

                                <div className="col-lg-5 d-flex flex-column justify-content-center">
                                    <h3>Notre philosophie de travail</h3>
                                    <p>« Notre expertise au service de vos projets », c’est notre slogan et il guide continuellement la collaboration avec nos clients, nos fournisseurs et nos partenaires. Cela passe par l’excellence de nos produits et services, par un conseil approprié et personnalisé, par une adaptation permanente de notre organisation, par le choix des meilleures technologies et systèmes d’information, par la valorisation des femmes et des hommes qui font la richesse de SAHEL GEO.
                                    </p>
                                    <p>Le travail que nous menons, avec la mise en œuvre de projets stratégiques ambitieux, a porté et continue à porter ses fruits. Notre entreprise s’impose continuellement et avec brio dans l’écosystème des entreprises du Sénégal.</p>
                                </div>
                                <div className="col-lg-6 img-bg" style={{ backgroundImage: 'url(assets/img/amadou-photo.jpeg)', backgroundPositionY: '-24px', height: "490px"}} data-aos="zoom-in" data-aos-delay={100} />
                            </div>
                        </div>
                    </section>

                    {/* ======= Services Section ======= */}
                    <section id="services" className="services section-bg">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Services proposés</h2>
                                <p>Découvrez nos solutions professionnelles pour répondre à vos besoins en géomatique et en génie civil.</p>
                            </div>
                            <div className="row gy-4 d-flex justify-content-center">
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={100}>
                                    <ReactCardFlip isFlipped={isFlipped1} flipDirection="horizontal">
                                        <div className="service-item  position-relative">
                                            <div className="icon">
                                                <i className="fa-solid fa-mountain-city" />
                                            </div>
                                            <h3>TOPOGRAPHIE ET CARTOGRAPHIE</h3>
                                            <p>Précision dans les relevés de terrain et création de cartes détaillées pour vos projets professionnels.</p>
                                            <button className="btn readmore stretched-link" onClick={handleClick1}>Plus d'info <i className="bi bi-arrow-right" /></button>
                                        </div>

                                        <div className="service-item  position-relative back-card" >
                                            <h3>Plus d'Infos</h3>
                                            <div className='service-list-item'>
                                                <ul>
                                                    <li><i class="bi bi-check2-all"></i>Levés topographiques</li>
                                                    <li><i class="bi bi-check2-all"></i>Implantations </li>
                                                    <li><i class="bi bi-check2-all"></i>Modélisation numérique de terrain </li>
                                                    <li><i class="bi bi-check2-all"></i>Planification, réalisation et contrôle de cartes topographiques, photocartes et cartes thématiques  </li>
                                                </ul>
                                            </div>
                                            <button className="btn readmore stretched-link" onClick={handleClick1}>Revenir <i className="bi bi-arrow-right" /></button>
                                        </div>
                                    </ReactCardFlip>

                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={200}>

                                    <ReactCardFlip isFlipped={isFlipped2} flipDirection="horizontal">
                                        <div className="service-item  position-relative">
                                            <div className="icon">
                                                <i className="fa-solid fa-arrow-up-from-ground-water" />
                                            </div>
                                            <h3>TOPOGRAPHIE MINIERE</h3>
                                            <p>Expertise topographique adaptée aux spécificités de l'industrie minière pour une planification optimale des sites.</p>


                                            <button className="btn readmore stretched-link" onClick={handleClick2}>Plus d'info <i className="bi bi-arrow-right" /></button>
                                        </div>

                                        <div className="service-item  position-relative back-card" >
                                            <h3>Plus d'Infos</h3>
                                            <div className='service-list-item'>
                                                <ul>
                                                    <li><i class="bi bi-check2-all"></i>Délimitation et bornage de concession minière</li>
                                                    <li><i class="bi bi-check2-all"></i>Localisation de points de sondage </li>
                                                    <li><i class="bi bi-check2-all"></i>Plan d’exploitation minière </li>
                                                    <li><i class="bi bi-check2-all"></i>Détermination de volume de minerais </li>
                                                    <li><i class="bi bi-check2-all"></i>Evaluation de stock</li>
                                                </ul>
                                            </div>
                                            <button className="btn readmore stretched-link" onClick={handleClick2}>Revenir <i className="bi bi-arrow-right" /></button>
                                        </div>
                                    </ReactCardFlip>



                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={300}>



                                    <ReactCardFlip isFlipped={isFlipped3} flipDirection="horizontal">
                                        <div className="service-item  position-relative">
                                            <div className="icon">
                                                <i className="fa-solid fa-trowel-bricks" />
                                            </div>
                                            <h3>GENIE CIVIL</h3>
                                            <p>Conception et supervision de constructions urbaines durables pour un développement harmonieux des villes et des infrastructures.</p>


                                            <button className="btn readmore stretched-link" onClick={handleClick3}>Plus d'info <i className="bi bi-arrow-right" /></button>
                                        </div>

                                        <div className="service-item  position-relative back-card" >
                                            <h3>Plus d'Infos</h3>
                                            <div className='service-list-item'>
                                                <ul>
                                                    <li><i class="bi bi-check2-all"></i>Etude de conception</li>
                                                    <li><i class="bi bi-check2-all"></i>Dimensionnement d’ouvrage </li>
                                                    <li><i class="bi bi-check2-all"></i>Contrôle qualité et assistance technique </li>
                                                    <li><i class="bi bi-check2-all"></i>Maitrise d’œuvre - OPC </li>
                                                </ul>
                                            </div>
                                            <button className="btn readmore stretched-link" onClick={handleClick3}>Revenir <i className="bi bi-arrow-right" /></button>
                                        </div>
                                    </ReactCardFlip>



                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={400}>


                                    <ReactCardFlip isFlipped={isFlipped4} flipDirection="horizontal">
                                        <div className="service-item  position-relative">
                                            <div className="icon">
                                                <i className="fa-solid fa-helmet-safety" />
                                            </div>
                                            <h3>ROUTE ET GEOTECHNIQUE</h3>
                                            <p>Analyse approfondie des sols et conception de routes sécurisées pour assurer une mobilité efficace.</p>


                                            <button className="btn readmore stretched-link" onClick={handleClick4}>Plus d'info <i className="bi bi-arrow-right" /></button>
                                        </div>

                                        <div className="service-item  position-relative back-card" >
                                            <h3>Plus d'Infos</h3>
                                            <div className='service-list-item'>
                                                <ul>
                                                    <li><i class="bi bi-check2-all"></i>Conception routière</li>
                                                    <li><i class="bi bi-check2-all"></i>Dimensionnement de fondations </li>
                                                    <li><i class="bi bi-check2-all"></i>Conception VRD ouvrage de soutènement </li>
                                                </ul>
                                            </div>
                                            <button className="btn readmore stretched-link" onClick={handleClick4}>Revenir <i className="bi bi-arrow-right" /></button>
                                        </div>
                                    </ReactCardFlip>




                                </div>{/* End Service Item */}
                                <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay={500}>


                                    <ReactCardFlip isFlipped={isFlipped5} flipDirection="horizontal">
                                        <div className="service-item  position-relative">
                                            <div className="icon">
                                                <i className="fa-solid fa-compass-drafting" />
                                            </div>
                                            <h3>GEOINFORMATION</h3>
                                            <p>Intégration de données géospatiales pour une analyse précise et une prise de décision éclairée dans divers secteurs.</p>
                                            <button className="btn readmore stretched-link" onClick={handleClick5}>Plus d'info <i className="bi bi-arrow-right" /></button>
                                        </div>

                                        <div className="service-item  position-relative back-card" >
                                            <h3>Plus d'Infos</h3>
                                            <div className='service-list-item'>
                                                <ul>
                                                    <li><i class="bi bi-check2-all"></i>Etude et implantation de systèmes d’information à référence spatiale (SIRS) </li>
                                                    <li><i class="bi bi-check2-all"></i>Gestion de données à référence spatiale appartenant à des sociétés privées </li>
                                                </ul>
                                            </div>
                                            <button className="btn readmore stretched-link" onClick={handleClick5}>Revenir <i className="bi bi-arrow-right" /></button>
                                        </div>
                                    </ReactCardFlip>




                                </div>{/* End Service Item */}
                                <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">



                                    <ReactCardFlip isFlipped={isFlipped6} flipDirection="horizontal">
                                        <div className="service-item  position-relative">
                                            <div class="icon">
                                            <i class="fa-solid fa-business-time"></i>
                                            </div>
                                            <h3>FORMATION</h3>
                                            <p>Programmes de formation spécialisés pour perfectionner vos compétences en géomatique et en génie civil.</p>


                                            <button className="btn readmore stretched-link" onClick={handleClick6}>Plus d'info <i className="bi bi-arrow-right" /></button>
                                        </div>

                                        <div className="service-item  position-relative back-card" >
                                            <h3>Plus d'Infos</h3>
                                            <div className='service-list-item'>
                                                <ul>
                                                    <li><i class="bi bi-check2-all"></i>Séminaires </li>
                                                    <li><i class="bi bi-check2-all"></i>Formation aux logiciels d’ingénieries </li>
                                                    <li><i class="bi bi-check2-all"></i>Formation à la carte </li>
                                                </ul>
                                            </div>
                                            <button className="btn readmore stretched-link" onClick={handleClick6}>Revenir <i className="bi bi-arrow-right" /></button>
                                        </div>
                                    </ReactCardFlip>



                                </div>{/* End Service Item */}

                            </div>
                        </div>
                    </section>{/* End Services Section */}


                    {/* ======= Constructions Section ======= */}
                    <section id="constructions" className="constructions">
                        <div className="container" data-aos="fade-up">
                            <div className="section-header">
                                <h2>Faites Nous Confiance</h2>
                                <p>Notre équipe d'experts est dédiée à fournir des services de qualité supérieure dans le domaine de la construction. Nous avons une vaste expérience dans la réalisation de projets de grande envergure et nous sommes fiers de notre travail. Faites-nous confiance pour réaliser vos projets de construction avec professionnalisme et excellence.</p>
                            </div>
                            <div className="row gy-4">
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="card-item">
                                        <div className="row">
                                            <div className="col-xl-5">
                                                <div className="card-bg" style={{ backgroundImage: 'url(assets/img/human-c.jpeg)' }} />
                                            </div>
                                            <div className="col-xl-7 d-flex align-items-center">
                                                <div className="card-body">
                                                    <h4 className="card-title">Capital humain</h4>
                                                    <p>Notre équipe est composée d’experts, d’ingénieurs, de technicien et d’administrateurs dotés de compétences exceptionnelles et dévoués à la réussite de votre projet.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* End Card Item */}
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={200}>
                                    <div className="card-item">
                                        <div className="row">
                                            <div className="col-xl-5">
                                                <div className="card-bg" style={{ backgroundImage: 'url(assets/img/drone.jpeg)' }} />
                                            </div>
                                            <div className="col-xl-7 d-flex align-items-center">
                                                <div className="card-body">
                                                    <h4 className="card-title">Équipement de dernière génération</h4>
                                                    <p>Des drones aux scanners en passant par les distomètres et autres capteurs, nous sommes dotés d’un équipement d’appoint pour le traitement et l’interprétation des données.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* End Card Item */}
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={300}>
                                    <div className="card-item">
                                        <div className="row">
                                            <div className="col-xl-5">
                                                <div className="card-bg" style={{ backgroundImage: 'url(assets/img/topo.jpeg)' }} />
                                            </div>
                                            <div className="col-xl-7 d-flex align-items-center">
                                                <div className="card-body">
                                                    <h4 className="card-title">Expertise avérée</h4>
                                                    <p>Nous avons une clientèle exigeante dans toute la sous-région à laquelle nous fournissons des services de standards internationaux.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* End Card Item */}
                                <div className="col-lg-6" data-aos="fade-up" data-aos-delay={400}>
                                    <div className="card-item">
                                        <div className="row">
                                            <div className="col-xl-5">
                                                <div className="card-bg" style={{ backgroundImage: 'url(assets/img/human-c2.jpeg)' }} />
                                            </div>
                                            <div className="col-xl-7 d-flex align-items-center">
                                                <div className="card-body">
                                                    <h4 className="card-title">Partenariats stratégiques</h4>
                                                    <p>Dans le soucis de vous offrir un service de qualité nous avons tissé des partenariats stratégiques avec les entreprises leaders de leur domaine.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* End Card Item */}
                            </div>
                        </div>
                    </section>{/* End Constructions Section */}

                    {/* Call To Action 1 - Bootstrap Brain Component */}
                    <section className="bsb-cta-1 px-2 bsb-overlay" style={{ backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/img/background.jpg")', backgroundAttachment: 'fixed', backgroundPosition: 'center', backgroundRepeat: 'no-repeat',   backgroundSize:  'cover' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h2 className="fs-5 mb-3 text-white text-default  text-uppercase">Vous avez un projet ?</h2>
                                    <h2 className="display-5 text-white mb-4">Laissez-nous un message et nous vous contacterons.</h2>
                                    <Link to={"/contact"} className="btn bsb-btn-2xl btn-light rounded mb-0 text-nowrap">Laissez un message</Link>
                                </div>
                            </div>
                        </div>
                    </section>



                    {/* ======= Testimonials Section ======= */}
                    <Testimonials />

                    {/* End Testimonials Section */}

                    <section id="clients" className="clients">
    <div className="container" data-aos="zoom-in">
        <div className="section-header">
            <h2>Nos Partenaires</h2>
        </div>
        <div className="clients-slider">
            <div className="swiper-wrapper partener-swapper align-items-center">
                <div className="">
                    <img src={require('../assets/img/partener/Dangote_Group_Logo.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/images-2.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/images-3.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/images.jpeg')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/LOGO-IRHIS-1.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/Original JPG-SOCOCIM_Logo.jpg')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/sodvit.jpg')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/7214bd002cd3fd0f7ff2232d18277dc16efe4947.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/Enabel_Logo_Color_RGB-1.jpg.webp')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/ics-logo-trsp-300x140.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/images.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
                <div className="">
                    <img src={require('../assets/img/partener/kpax.png')} className="img-fluid" alt style={{width:"120px", marginRight: "30px"}} />
                </div>
            </div>
            <div className="swiper-pagination" />
        </div>
    </div>
</section>





                </section>{/* End Hero Section */}

            </div>

        </>
    );
};

export default Home;