import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Header = () => {
    const [mobileNavVisible, setMobileNavVisible] = useState(false);

    const toggleMobileNav = () => {
        setMobileNavVisible(!mobileNavVisible);
    };

    const closeMobileNav = () => {
        setMobileNavVisible(false);
    };

    useEffect(() => {
        if (mobileNavVisible) {
            document.body.classList.add('mobile-nav-active');
        } else {
            document.body.classList.remove('mobile-nav-active');
        }
    }, [mobileNavVisible]);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <>
            {/* ======= Header ======= */}
            <header id="header" className="header d-flex align-items-center">
                <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo d-flex align-items-center">
                        {/* Uncomment the line below if you also wish to use an image logo */}
                        {/* <img src="assets/img/logo.png" alt=""> */}
                        <img src="assets/img/logo.png" alt=""/>
                    </Link>
                    <i onClick={toggleMobileNav} className={`mobile-nav-toggle bi bi-list ${mobileNavVisible ? 'd-none' : 'mobile-nav-show'}`} />
                    <i onClick={toggleMobileNav} className={`mobile-nav-toggle bi bi-x ${mobileNavVisible ? 'mobile-nav-hide' : 'd-none'}`} />
                    <nav id="navbar" className={`navbar ${mobileNavVisible ? 'mobile-nav-active' : ''}`}>
                        <ul>
                            <li><NavLink to="/" activeClassName="active" onClick={closeMobileNav}>Accueil</NavLink></li>
                            <li><NavLink to="/about" activeClassName="active" onClick={closeMobileNav}>A propos</NavLink></li>
                            <li className={`dropdown ${dropdownOpen ? 'active' : ''}`} onClick={toggleDropdown}>
                                <NavLink to="/activity" activeClassName="active">
                                    <span>Activités</span> 
                                    <i className="bi bi-chevron-down dropdown-indicator" />
                                </NavLink>
                                <ul className={`dropdown-menu ${dropdownOpen ? 'dropdown-active' : ''}`}>
                                    <li><Link to="/geomatic" onClick={closeMobileNav}>Géomatique</Link></li>
                                    <li><Link to="/civil" onClick={closeMobileNav}>Génie Civil</Link></li>
                                    <li><Link to="/agriculture" onClick={closeMobileNav}>Agriculture de Précision</Link></li>
                                    <li><Link to="/rd" onClick={closeMobileNav}>Recherche & Développement</Link></li>
                                    <li><Link to="/formation" onClick={closeMobileNav}>Formation</Link></li>
                                </ul>
                            </li>
                            <li><NavLink to="/references" activeClassName="active" onClick={closeMobileNav}>Références</NavLink></li>
                            <li><NavLink to="/carriers" activeClassName="active" onClick={closeMobileNav}>Carrières</NavLink></li>
                            <li><NavLink to="/blog" activeClassName="active" onClick={closeMobileNav}>Blog</NavLink></li>
                            <li>
                                <Link to="/contact" className="btn btn-primary rounded-0 p-2 primary-color border-0 text-white" onClick={closeMobileNav}>Nous contacter</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>{/* End Header */}
        </>
    );
};

export default Header;
