import axios from 'axios'
import { base_url, config } from "../../utils/axiosconfig"

const createComment = async (data) => {
    try {
        const response = await axios.post(`${base_url}comment`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getComment = async () => {
    try {
        const response = await axios.get(`${base_url}comment`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getCommentById = async (id) => {
    try {
        const response = await axios.get(`${base_url}comment/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const updateComment = async (data) => {
    try {
        const response = await axios.put(`${base_url}comment/${data.id}`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const deleteComment = async (id) => {
    try {
        const response = await axios.delete(`${base_url}comment/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const commentService = {
    getComment,
    getCommentById,
    createComment,
    updateComment,
    deleteComment
}