import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { userService } from './userService';

const getCustomerfromLocalStorage = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (data, thunkAPI) => {
        console.log('getCustomerfromLocalStorage', getCustomerfromLocalStorage)
        const response  = await userService.loginUser(data)
        return response
    }
)

export const getAllUser = createAsyncThunk(
    'user/getAllUser',
    async () => {
        const response = await userService.getUser()
        return response
    }
)

export const getUserById = createAsyncThunk(
    'user/getUserById',
    async (id) => {
        const response = await userService.getUserById(id)
        return response
    }
)

export const addUser = createAsyncThunk(
    'user/addUser',
    async (data) => {
        const response = await userService.createUser(data)
        return response
    }
)

export const updateUser = createAsyncThunk(
    'user/updateUser',
    async (data) => {
        const response = await userService.updateUser(data)
        return response
    }
)

export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (id) => {
        const response = await userService.deleteUser(id)
        return response
    }
)

export const logoutUser = createAsyncThunk(
    'user/logoutUser',
    async () => {
        const response = await userService.logoutUser()
        return response
    }
)

const initialState = {
    user: getCustomerfromLocalStorage,
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
 }

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllUser.pending, (state) => {
                state.status = 'loading'
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(getAllUser.fulfilled, (state, action) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.users = action.payload
            })
            .addCase(getAllUser.rejected, (state) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
            .addCase(getUserById.pending, (state) => {
                state.status = 'loading'
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.user = action.payload
            })
            .addCase(getUserById.rejected, (state) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
            })
            .addCase(addUser.pending, (state) => {
                state.status = 'loading'
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(addUser.fulfilled, (state, action) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.users.push(action.payload)
                toast.success('User added successfully')
            })
            .addCase(addUser.rejected, (state) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                toast.error('Failed to add user')
            })
            .addCase(updateUser.pending, (state) => {
                state.status = 'loading'
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.users = state.users.map(user => {
                    if (user.id === action.payload.id) {
                        return action.payload
                    }
                    return user
                })
                toast.success('User updated successfully')
            })
            .addCase(updateUser.rejected, (state) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                toast.error('Failed to update user')
            })
            .addCase(deleteUser.pending, (state) => {
                state.status = 'loading'
                state.isLoading = true
                state.isSuccess = false
                state.isError = false
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = true
                state.isError = false
                state.users = state.users.filter(user => user.id !== action.payload.id)
                toast.success('User deleted successfully')
            })
            .addCase(deleteUser.rejected, (state) => {
                state.status = 'idle'
                state.isLoading = false
                state.isSuccess = false
                state.isError = true
                toast.error('Failed to delete user')
            })
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
             })
             .addCase(loginUser.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.message = "Success";
                state.user = action.payload;
                if (state.isSuccess === true) {
                   toast.info('Login Successfully')
                }
             })
             .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.isSuccess = false;
                state.message = action.error;
                if (state.isError === true) {
                   toast.error(state.message.message)
                }
             })
             .addCase(logoutUser.pending, (state) => {
                state.isLoading = true;
             })
            .addCase(logoutUser.fulfilled, (state) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.user = null;
                localStorage.removeItem('user');
                toast.info('Logout Successfully')
            })
            .addCase(logoutUser.rejected, (state) => {
                state.isLoading = false;
                state.isError = true;
                toast.error('Failed to logout')
            })
    }
})

export default userSlice.reducer