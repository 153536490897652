import React from 'react';
import { Link } from 'react-router-dom';

const Geomatic = () => {
    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>

                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Géomatique</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li><Link to="/activity">Activités</Link></li>
                            <li>Géomatique</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                {/* ======= Constructions Section ======= */}
                <section id="constructions" className="constructions">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 class="fw-bold">TOPOGRAPHIE</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 class="fw-bold">Levés topographiques</h6>
                                            <p>Les prestations en relevés topographiques proposées par notre cabinet sont adaptées à tous types de projets d’aménagement du territoire ou à tous autres besoins plus spécifiques, qui peuvent émaner de :</p>
                                            <ul>
                                                <li>Collectivités territoriales</li>
                                                <li>Bureaux d’études</li>
                                                <li>Exploitants d’infrastructures routières ou industrielles, ou de réseaux</li>
                                                <li>Architectes et urbanistes</li>
                                                <li>Promoteurs et aménageurs immobiliers</li>
                                            </ul>
                                            <p>Pour effectuer ce type de prestation, nous sommes en mesure de déployer plusieurs équipes pluridisciplinaires et expérimentées, tout en étant très réactifs et capables de garantir une réponse à vos besoins dans les meilleurs délais.</p>
                                            <h6 class="fw-bold">Implantation</h6>
                                            <p>L’implantation consiste à transposer et matérialiser physiquement des éléments calculés sur plan. Il peut s’agir de l’établissement de limites parcellaires nouvelles, d’axes de voies à créer, d’altitudes à positionner.</p>
                                            <p>Une fois les implantations réalisées, des contrôles sont effectués pour valider celles-ci, un procès-verbal est dressé et signé conjointement avec le client ; sur ce procès-verbal sont portées des indications permettant à l’utilisateur de vérifier la stabilité des repères laissés par le géomètre-expert avant toute utilisation.</p>
                                            <h6 class="fw-bold">Modélisation numérique de Terrain</h6>
                                            <p>Elle permet la représentation numérique de la topographie du terrain. C’est une pièce maitresse permettant l’extraction de nombreux paramètres tels que la pente, les axes d’écoulement, etc. Ces paramètres sont régulièrement utilisés comme outils de décision dans la gestion de l’environnement.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 class="fw-bold">TOPOGRAPHIE MINIERE</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 class="fw-bold">Délimitation et bornage de concession minière</h6>
                                            <p>Cette activité consiste à fixer définitivement la limite de deux terrains contigus et à marquer cette limite par des repères matériels appelés « bornes ».</p>
                                            <h6 class="fw-bold">Plans d’exploitations minières</h6>
                                            <p>Nous vous accompagnons tout au long de vos projets d’exploration/d’exploitation minière. Pour cela nous disposons de logiciels permettant d’élaborer les plans topographiques avec courbes de niveau, profils en long en travers, inventaire des stocks, simulation et détection de conflits afin de vous garantir la sécurité du début à la fin.</p>
                                            <h6 class="fw-bold">Détermination de volumes de minerai</h6>
                                            <p>Nos outils permettent de modéliser le site d’exploitation, de réaliser des pistes et chemins d’accès au site, de définir le contour géométrique du site d’exploitation afin d’en déterminer le volume.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">CARTOGRAPHIE</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Cartes topographiques</h6>
                                            <p>Ce sont des cartes à échelle réduite que nous réalisons et qui représentent le relief déterminé par altimétrie et les aménagements humains d’une région géographique de manière précise et détaillée sur un plan horizontal.</p>
                                            <h6 className="fw-bold">Photocartes</h6>
                                            <p>Nous produisons des cartes dont le fond planimétrique est obtenu en assemblant soit des photographies aériennes réduites à une échelle moyenne, soit des orthophotographies.</p>
                                            <h6 className="fw-bold">Cartes thématiques</h6>
                                            <p>Elles sont utilisées pour présenter des données sur un thème précis. Ces informations sont réparties sur un territoire présenté par un fond de carte. Les thèmes peuvent être de natures diverses : répartition de la population, activités économiques, production agricole, production industrielle, végétation, climat, ressources naturelles, etc.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">GEOINFORMATION</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Étude et Implantation de systèmes d’information à référence spatiale (SIRS)</h6>
                                            <p>Ce système permet d’assurer la gestion des (géo) données sans redondance en simplifiant et optimisant la saisie, la mise à jour, l’analyse et la consultation.</p>
                                            <h6 className="fw-bold">Gestion de données à référence spatiale appartenant à des sociétés privées</h6>
                                            <p>Nous disposons de logiciels comme ARCGIS, QGIS et autres outils de gestion de base de données que nous mettons à la disposition des entreprises. Ces dispositifs permettront de :</p>
                                            <ul>
                                                <li>Suivre l’évolution des données dans le temps et pouvoir les interpréter</li>
                                                <li>Assurer la mise à jour et l’archivage</li>
                                                <li>Faciliter la prise de décision</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>





                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">CADASTRE & URBANISATION</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Enquêtes cadastrales</h6>
                                            <p>Nos enquêtes cadastrales comprennent plusieurs phases :</p>
                                            <ul>
                                                <li>Communication</li>
                                                <li>Installation des bureaux d’appui</li>
                                                <li>Enquêtes sur le terrain</li>
                                                <li>Validation</li>
                                                <li>Saisie</li>
                                                <li>Suivi et coordination de projet</li>
                                            </ul>
                                            <h6 className="fw-bold">Aménagements fonciers</h6>
                                            <p>Ces travaux permettent :</p>
                                            <ul>
                                                <li>D’améliorer les conditions d’exploitation des propriétés rurales agricoles ou forestières</li>
                                                <li>D’assurer la mise en valeur des espaces naturels ruraux</li>
                                                <li>De contribuer à l’aménagement du territoire communal ou intercommunal</li>
                                            </ul>
                                            <h6 className="fw-bold">Lotissement</h6>
                                            <p>Nos travaux de lotissement permettent la division en propriétés d’unités foncières dans le but de créer un ou plusieurs lots en vue d’y recevoir des constructions qui sont vendues ensemble ou plus généralement séparément après que le lotisseur ait réalisé des voies d’accès, des espaces collectifs, des travaux de viabilité et des raccordements aux réseaux de fourniture en eau, en électricité, aux réseaux d’égouts et aux réseaux de télécommunication.</p>
                                            <h6 className="fw-bold">Morcellement</h6>
                                            <p>Nos travaux de morcellement d’un titre foncier consistent à démembrer un terrain à la suite de ventes successives ou de partages au profit des acquéreurs.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>
                </section>{/* End Constructions Section */}



            </main>{/* End #main */}


        </>
    );
};

export default Geomatic;