import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Activity from './pages/Activity';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Carriers from './pages/Carriers';
import References from './pages/References';
import Layout from './components/Layout';
import Agriculture from './pages/Activities/Agriculture';
import Civil from './pages/Activities/Civil';
import Formation from './pages/Activities/Formation';
import Geomatic from './pages/Activities/Geomatic';
import RD from './pages/Activities/RD';
import { BlogDetail} from './pages/Blog-detail';
import { FilterPage } from './pages/Filter-page'
import FAQ from './pages/FAQ';
import { Admin } from './Admin';

 
function App() {
  return (
    <Router>
       <Routes>
          <Route path="/" element={<Layout />}>
             <Route index element={<Home />} />
             <Route path="about" element={<About />} />
             <Route path="activity" element={<Activity />} />
              <Route path="blog" element={<Blog />} />
              <Route path="contact" element={<Contact />} />
              <Route path="carriers" element={<Carriers />} />
              <Route path="references" element={<References />} />
              <Route path="geomatic" element={<Geomatic />} />
              <Route path="civil" element={<Civil />} />
              <Route path="agriculture" element={<Agriculture />} />
              <Route path="rd" element={<RD />} />
              <Route path="formation" element={<Formation />} />
               <Route path="blog-details/:id" element={<BlogDetail />} />
               <Route path="filter" element={<FilterPage />} />
               <Route path="faq" element={<FAQ />} />
          </Route>
          <Route path="admin/*" element={<Admin />} />
               
       </Routes>
    </Router>
 );
}



export default App;
