import React from 'react';
import { AdminLayout } from './AdminLayout';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getAllTag } from '../../features/tag/tagSlice';
import { getAllCategory } from '../../features/category/categorySlice';
import { updateBlog ,getBlogById, deleteBlog } from '../../features/blog/blogSlice';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { uploadImg } from '../../features/upload/uploadSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ViewPost = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tagInfo = useSelector((state) => state.tag);
    const categoryInfo = useSelector((state) => state.category);
    const [isLoading, setIsLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [images, setImage] = useState("");

    const [content, setContent] = useState('');
    const [category, setCategory] = useState('');
    const [tags, setSelectedTags] = useState([]);
    const imgState = useSelector((state) => state.upload.images);
    const blogState = useSelector((state) => state.blog);

    const location = useLocation();
    const getBlogId = location.pathname.split('/')[3];

    const {
        isSuccess,
        isError,
        blogImages,
      } = blogState;
    

    

    const img = [];
    imgState.forEach((i) => {
        img.push({
        public_id: i.public_id,
        url: i.url,
        });
    });
    

    useEffect(() => {
        setImage(img);
    }, [blogImages]);

    useEffect(() => {
        const fetchBlog = async () => {
            if (getBlogId) {
                const blog = await dispatch(getBlogById(getBlogId));
                setTitle(blog?.payload?.title);
                setContent(blog?.payload?.content);
                setImage(blog?.payload?.images);
                setCategory(blog?.payload?.category);
                setSelectedTags(blog?.payload?.tags);

            }
        };
        fetchBlog();
    }, [dispatch, getBlogId]);


 

    useEffect(() => {
        Promise.all([
            dispatch(getAllTag()),
            dispatch(getAllCategory())
        ]).then(() => setIsLoading(false));
    }, [dispatch]);

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleContentChange = (value) => {
        setContent(value);
    };


    const [showImage, setShowImage] = useState(true);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleImageChange = (e) => {
        setIsImageUploading(true);
        setImage([]);
        setShowImage(false);
        console.log("e.target.files", e.target.files);
        dispatch(uploadImg(e.target.files)).then((response) => {
            console.log("response", response);
            setImage({
                public_id: response.payload[0].public_id,
                url: response.payload[0].url,
            });
            });
            setIsImageUploading(false);
    };

    if (isImageUploading) {
        return <div id="preloader"></div>;
    }

    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleTagChange = (event) => {
        const tagId = event.target.value;
        if (event.target.checked) {
            setSelectedTags(prevTags => [...prevTags, tagId]);
        } else {
            setSelectedTags(prevTags => prevTags.filter(id => id !== tagId));
        }
    };

  

    const handleSubmit = () => {    
        setIsUpdating(true);
        dispatch(updateBlog({ title: title, content: content, images: images, category: category, tags: tags, id: getBlogId})).then(() => {
            toast.success('Article mis à jour avec succès');
            navigate('/admin');
        
        });

    };

    if (isUpdating) {
        return <div>Mise à jour...</div>; 
    }



    


    const deletePost = () => {
        setIsDeleting(true);
        dispatch(deleteBlog(getBlogId))
        .then(() => {
            toast.success('Post supprimé avec succès');
            navigate('/admin');
        });
    };

    if (isDeleting) {
        return <div>Suppression...</div>; // Ou tout autre composant de chargement que vous souhaitez afficher pendant la suppression
    }

    if (isLoading) {
        return <div id="preloader"></div>;
    }
    const tagList = tagInfo.tags;
    const categoryList = categoryInfo.categories;


    return (
        <AdminLayout>
            <section className="section p-0">
                <div className="row" style={{ width: "88%" }}>
                    <div className="col-lg-12 me-2">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="admin-card-title">Informations de l'Article</h3>
                                <form onSubmit={handleSubmit}>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Titre de l'article</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" value={title} onChange={handleTitleChange} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="" className="col-sm-2">Contenu de l'article</label>
                                        <div className="col-sm-10 mb-5">
                                            <ReactQuill theme="snow" className="" style={{ height: '200px' }} defaultValue={""} value={content} onChange={handleContentChange} />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label htmlFor="inputNumber" className="col-sm-2 col-form-label">Charger une image</label>
                                        <div className="col-sm-10">
                                            <input className="form-control" type="file" id="formFile" onChange={handleImageChange}/>
                                            {showImage &&
                                                 <img id="defaultImage" src={images[0]?.url} alt="Pas d'image enregistrée" style={{width: "275px", height:  "183px"}}/>
                                            }

                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-sm-2 col-form-label">Catégorie de l'article</label>
                                        <div className="col-sm-10">
                                            <select className="form-select" aria-label="Default select example" value={category} onChange={handleCategoryChange}>
                                                <option selected>Selectionnez une Catégorie</option>
                                                {categoryList.map((category) => (
                                                    <option value={category?._id}>{category?.title}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <legend className="col-form-label col-sm-2 pt-0">Tags</legend>
                                        <div className="col-sm-10">
                                            {tagList.map((tag) => (
                                                <div className="form-check" key={tag?._id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value={tag?._id}
                                                        id={`tag-${tag?._id}`}
                                                        onChange={handleTagChange}
                                                        defaultChecked={tags.includes(tag?._id)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`tag-${tag?._id}`}>
                                                        {tag?.title}
                                                    </label>
                                                </div>
                                            ))}

                                        </div>
                                    </div>


                                    <div className="row mb-3">
                                        <div className="col-sm-10 w-100 d-flex justify-content-end me-5">
                                            <button className="btn btn-danger mx-3" onClick={deletePost}>Supprimer</button>
                                            <button type="submit" className="btn btn-primary">Postez</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>



        </AdminLayout>
    );
};

export { ViewPost };