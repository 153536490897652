import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Activity = () => {
    return (
        <main id="main">
        {/* ======= Breadcrumbs ======= */}
<div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>
            <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
            <h2>Activités</h2>
            <ol>
                <li><Link to="/">Accueil</Link></li>
                <li>Activités</li>
            </ol>
            </div>
        </div>{/* End Breadcrumbs */}
                {/* ======= Features Section ======= */}
                <section id="features" className="features  activity section-bg">
                    <div className="container" data-aos="fade-up">
                        <ul className="nav nav-tabs row  g-2 d-flex">
                            <li className="nav-item col">
                                <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
                                    <h4>Géomatique</h4>
                                </a>
                            </li>{/* End tab nav item */}
                            <li className="nav-item col">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
                                    <h4>Génie Civil</h4>
                                </a>{/* End tab nav item */}
                            </li><li className="nav-item col">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
                                    <h4>Agriculture de Précision</h4>
                                </a>
                            </li>{/* End tab nav item */}
                            <li className="nav-item col">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
                                    <h4>Recherche Dev</h4>
                                </a>
                            </li>{/* End tab nav item */}
                            <li className="nav-item col">
                                <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5">
                                    <h4>Formation</h4>
                                </a>
                            </li>{/* End tab nav item */}
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active show" id="tab-1">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={100}>
                                        <h3>Géomatique</h3>
                                        <p className="fst-italic">
                                        Intégration de données géospatiales pour une analyse précise et une prise de décision éclairée dans divers secteurs. Voici quelques-uns des services que nous offrons:
                                        </p>
                                        <div>
                                            <ul>
                                                <li><i className="bi bi-check2-all" />Topographie</li>
                                                <li><i className="bi bi-check2-all" />Topographie minière </li>
                                                <li><i className="bi bi-check2-all" />Cartographie </li>
                                                <li><i className="bi bi-check2-all" />Géoinformation</li>
                                                <li><i className="bi bi-check2-all" />Cadastre et Urbanisation </li>
                                                <li><i className="bi bi-check2-all" />Evaluations </li>
                                            </ul>
                                        </div>
                                        <Button variant="warning" href='/geomatic' className='p-2 py-3'><p className="fst-normal text-white h5">Découvrir</p></Button>
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay={200}>
                                        <img src="assets/img/geomatic.jpg" alt className="img-fluid" />
                                    </div>
                                </div>
                            </div>{/* End tab content item */}
                            <div className="tab-pane" id="tab-2">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Génie Civil</h3>
                                        <p className="fst-italic">
                                        Expertise dans l'ingénierie pour des infrastructures durables et sécurisées. Nous proposons des services de:                                       
                                        </p>
                                        <div className='tab-service-item'>
                                            <ul>
                                                <li><i className="bi bi-check2-all" />Etude de conception </li>
                                                <li><i className="bi bi-check2-all" />Dimensionnement d'ouvrage </li>
                                                <li><i className="bi bi-check2-all" />Contrôle qualité et assistance technique </li>
                                                <li><i className="bi bi-check2-all" />Maitrise d'œuvre - OPC </li>
                                            </ul>
                                        </div>
                                        <Button variant="warning" href='/civil' className='p-2 py-3'><p className="fst-normal text-white h5">Découvrir</p></Button>

                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                        <img src="assets/img/gc.jpeg" alt="" className="img-fluid" style={{width: "636px",  height:"477px", objectFit: "cover"}}/>
                                    </div>
                                </div>
                            </div>{/* End tab content item */}
                            <div className="tab-pane" id="tab-3">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Agriculture de Précision</h3>
                                        <p className="fst-italic">
                                        Utilisation avancée de la technologie pour optimiser les rendements agricoles. Nos services comprennent :                                        
                                        </p>
                                        <div className='tab-service-item'>
                                        <ul>
                                            <li><i className="bi bi-check2-all" />Cartographie des sols </li>
                                            <li><i className="bi bi-check2-all" />Télédétection </li>
                                            <li><i className="bi bi-check2-all" />Gestion de l'irrigation </li>
                                            <li><i className="bi bi-check2-all" />Gestion des cultures de précision </li>
                                        </ul>
                                        </div>
                                        <Button variant="warning" href='/agriculture' className='p-2 py-3'><p className="fst-normal text-white h5">Découvrir</p></Button>

                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src="assets/img/ap.jpeg" alt="" className="img-fluid" style={{width: "636px",  height:"477px", objectFit: "cover"}}/>
                                    </div>
                                </div>
                            </div>{/* End tab content item */}
                            <div className="tab-pane" id="tab-4">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Recherche & Développement</h3>
                                        <p className="fst-italic">
                                        Innovation constante pour des solutions novatrices et performantes. En plus de la recherche, nous offrons des services de:                                        
                                        </p>
                                        <div className='tab-service-item'>
                                        <ul>
                                            <li><i className="bi bi-check2-all" />Instrumentation et Suivi d'ouvrages </li>
                                            <li><i className="bi bi-check2-all" />Inspection de chaussées </li>
                                            <li><i className="bi bi-check2-all" />Développement de logiciels de géomatique </li>
                                            <li><i className="bi bi-check2-all" />Auscultation </li>
                                        </ul>
                                        </div>
                                        <Button variant="warning" href='/rd' className='p-2 py-3'><p className="fst-normal text-white h5">Découvrir</p></Button>

                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src="assets/img/rd1.png" alt="" className="img-fluid" style={{width: "477px",  height:"477px", objectFit: "cover"}}/>
                                    </div>
                                </div>
                            </div>{/* End tab content item */}
                            <div className="tab-pane" id="tab-5">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                                        <h3>Formation</h3>
                                        <p className="fst-italic">
                                        Programme de développement professionnel pour renforcer vos compétences. Nous proposons une gamme de cours spécialisés adaptés à vos besoins:                                        </p>
                                        <div className='tab-service-item'>
                                        <ul>
                                            <li><i className="bi bi-check2-all" />Séminaires </li>
                                            <li><i className="bi bi-check2-all" />Formation aux logiciels d'ingénierie </li>
                                            <li><i className="bi bi-check2-all" />Formations à la carte </li>
                                        </ul>
                                        </div>
                                        <Button variant="warning" href='/formation' className='p-2 py-3'><p className="fst-normal text-white h5">Découvrir</p></Button>

                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center">
                                    <img src="assets/img/formation.png" alt="" className="img-fluid" style={{width: "477px",  height:"477px"}}/>
                                    </div>
                                </div>
                            </div>{/* End tab content item */}
                        </div>
                    </div>
                </section>{/* End Features Section */}
        </main>

    );
};

export default Activity;