import React from 'react';
import { AdminLayout } from './AdminLayout';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOpts,
    TableBody,
    TableHeader
  } from 'react-bs-datatable';
  import { Col, Row, Table } from 'react-bootstrap';
  import { getAllFaq, deleteFaq } from '../../features/faq/faqSlice';
    import { useDispatch } from 'react-redux';
    import { useEffect, useState } from 'react';
    import { useSelector } from 'react-redux';
    import { Link } from 'react-router-dom';



const FaqList = () => {


    const dispatch = useDispatch();

    const faqList = useSelector((state) => state.faq);

    useEffect(() => {
        dispatch(getAllFaq());
    }
    , [dispatch]);


    const body = faqList.faqs;

    const headers = [
        { title: '#', prop: 'index'},
        { title: 'Question', prop: 'title' },
        { title: 'Date', prop: 'createdAt' },
       { title: 'Actions', prop: 'actions'}
      ];


    const handleDelete = (id) => {
        dispatch(deleteFaq(id)).then(() => {
            dispatch(getAllFaq());
        }
        );
    };

      const TableBody = () => {
        return (
            <tbody>
                {body.map((row, index) => (
                    <tr key={row.id}>
                        <td>{index + 1}</td>
                        <td>{row.title}</td>
                        <td>{new Date(row.createdAt).toLocaleDateString("fr-FR")}</td>
                        <td>
                        <Link
                                to={`/admin/faq/${row?._id}`}
                                className=" fs-3 text-danger"
                            >
                                <button className='btn'>
                                    <i class="bi bi-pencil-square"></i>
                                </button>
                            </Link>
                        <button className='btn' onClick={() => handleDelete(row._id)}>
                            <i class="bi bi-trash"></i>
                        </button>
                        </td>
                    </tr> 
                ))}
            </tbody>
        );
    };

    return (
        <AdminLayout>
            <section className="section p-0">
                <div className="row" style={{width: "88%"}}>
                    <div className="col-lg-12 me-2">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="admin-card-title">Liste des Faqs</h3>
                                <DatatableWrapper body={body} headers={headers}>
                                <Table className='text-center'>
                                    <TableHeader />
                                    <TableBody />
                                </Table>
                                </DatatableWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </AdminLayout>
    );
};

export { FaqList };