import React from 'react';
import { Link } from 'react-router-dom'


const Agriculture = () => {
    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>

                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Agriculture et précision</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li><Link to="/activity">Activités</Link></li>
                            <li>Agriculture</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}

                {/* ======= Constructions Section ======= */}
                <section id="constructions" className="constructions">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">
                            <div className="col-lg-12">

                                <div className="col-lg-12">
                                    <div className="container" data-aos="fade-away">
                                        <div className="card custom-card">
                                            <div className='card-header text-center'>
                                                <h4 className="fw-bold">AGRICULTURE DE PRECISION</h4>
                                            </div>
                                            <div className="card-body">
                                                <p>Le principe de l’agriculture de précision, c’est d’augmenter les rendements d’une parcelle tout en réduisant la consommation d’énergie et d’intrants. Pour cela, nous opérons grâce à l’utilisation de nouvelles technologies. L’idée est ainsi de produire plus avec moins.</p>
                                                <p>Nous utilisons des moyens d’observation (drones, capteurs connectés, …) associés à des outils d’aide à la décision (accessibles sous forme d’applications Web et Mobile) afin de collecter un maximum de données intéressantes. Ces données servent à améliorer le quotidien des agriculteurs.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>{/* End Constructions Section */}


            </main>{/* End #main */}


        </>
    );
};

export default Agriculture;