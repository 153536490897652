import React from 'react';
import { AdminLayout } from './AdminLayout';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOpts,
    TableBody,
    TableHeader
  } from 'react-bs-datatable';
  import { Col, Row, Table } from 'react-bootstrap';
  import { getAllCategory,  deleteCategory } from '../../features/category/categorySlice';
    import { useDispatch } from 'react-redux';
    import { useEffect, useState } from 'react';
    import { useSelector } from 'react-redux';
    import { Link } from 'react-router-dom';



const CategoryList = () => {


    const dispatch = useDispatch();

    const categoryList = useSelector((state) => state.category);
    console.log(categoryList);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
            setIsLoading(true);
            dispatch(getAllCategory()).then(() => setIsLoading(false));
    }
    , [dispatch]);

   


    const body = categoryList?.categories;

    const headers = [
        { title: '#', prop: 'index'},
        { title: 'Nom de la Catégories', prop: 'title' },
       { title: 'Date de création', prop: 'createdAt'},
       { title: 'Actions', prop: 'actions'}
      ];

    const handleDelete = (id) => {
        dispatch(deleteCategory(id)).then(() => {
            dispatch(getAllCategory());
        }
        );
    };

    if (isLoading) {
        return <div id="preloader"></div>;
    }

      const TableBody = () => {
        return (
            <tbody>
                {body?.map((row, index) => (
                    <tr key={row?.id}>
                        <td>{index + 1}</td>
                        <td>{row?.title}</td>
                        <td>{new Date(row?.createdAt).toLocaleDateString("fr-FR")}</td>
                        <td>
                        <Link
                                to={`/admin/category/${row?._id}`}
                                className=" fs-3 text-danger"
                            >
                                <button className='btn'>
                                    <i class="bi bi-pencil-square"></i>
                                </button>
                            </Link>
                        <button className='btn' onClick={() => handleDelete(row._id)}>
                            <i class="bi bi-trash"></i>
                        </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    };

    return (
        <AdminLayout>
            <section className="section p-0">
                <div className="row" style={{width: "88%"}}>
                    <div className="col-lg-12 me-2">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="admin-card-title">Liste des Catégories</h3>
                                <DatatableWrapper body={body} headers={headers}>
                                <Table className='text-center'>
                                    <TableHeader />
                                    <TableBody />
                                </Table>
                                </DatatableWrapper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </AdminLayout>
    );
};

export { CategoryList };