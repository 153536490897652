import React from 'react';
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <main id="main">
        {/* ======= Breadcrumbs ======= */}
<div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>
            <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
            <h2>Contact</h2>
            <ol>
                <li><Link to="/">Accueil</Link></li>
                <li>Contact</li>
            </ol>
            </div>
        </div>{/* End Breadcrumbs */}
        {/* ======= Contact Section ======= */}
        <section id="contact" className="contact section-bg">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
            <div>
            <div className="row gy-4">
                <div className="col-lg-6">
                <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-map" />
                    <h3>Notre Adresse </h3>
                    <p>Parcelles assainies Unité 9, Rue CA 01, Dakar</p>
                </div>
                </div>{/* End Info Item */}
                <div className="col-lg-3 col-md-6">
                <div className="info-item d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-envelope" />
                    <h3>Envoyez nous un Mail</h3>
                    <p>contact@sahelgeomatique.com</p>
                </div>
                </div>{/* End Info Item */}
                <div className="col-lg-3 col-md-6">
                <div className="info-item  d-flex flex-column justify-content-center align-items-center">
                    <i className="bi bi-telephone" />
                    <h3>Appelez Nous</h3>
                    <p>{"(+221) 33 835 78 14"}</p>
                </div>
                </div>{/* End Info Item */}
            </div>
            <div className="row gy-4 mt-1">
                <div className="col-lg-6 ">
                <iframe 
                            width="100%" 
                            height="384" 
                            frameborder="0" 
                            marginheight="0" 
                            marginwidth="0" 
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sahel%20G%C3%A9omatique%20Rue%20CA%2001,%20Dakar,%20Senegal+(Sahel%20G%C3%A9omatique%20)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            title="SahelGeo Location">
                        </iframe>
                </div>{/* End Google Maps */}
                <div className="col-lg-6">
                <form action="#" method="post" role="form" className="php-email-form">
                    <div className="row gy-4">
                    <div className="col-lg-6 form-group">
                        <input type="text" name="name" className="form-control" id="name" placeholder="Votre Nom" required />
                    </div>
                    <div className="col-lg-6 form-group">
                        <input type="email" className="form-control" name="email" id="email" placeholder="Votre Email" required />
                    </div>
                    </div>
                    <div className="form-group">
                    <input type="text" className="form-control" name="subject" id="subject" placeholder="Object" required />
                    </div>
                    <div className="form-group">
                    <textarea className="form-control" name="message" rows={5} placeholder="Message" required defaultValue={""} />
                    </div>
                    <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message" />
                    <div className="sent-message">Votre message a été envoyé !</div>
                    </div>
                    <div className="text-center"><button type="submit">Envoyer</button></div>
                </form>
                </div>{/* End Contact Form */}
            </div>
            </div>
        </div>

        </section>{/* End Contact Section */}
        
        </main>

    );
};

export default Contact;