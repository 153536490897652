import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import { Navigation, Autoplay, Pagination } from 'swiper/modules';

const Testimonials = () => {
    const testimonials = [
        {
            quote: 'Tout s’est réalisé de façon très professionnelle avec le souci de la satisfaction du client dans les moindres détails. Merci SAHEL GEO !'
        },
        {
            quote: 'SAHEL GEO a fait preuve d’un professionnalisme exemplaire tout au long de l’opération. Nous soulignons particulièrement les qualités humaines et techniques de l’entreprise, nécessaires pour mener à bien des chantiers de cette envergure.'
        },
        {
            quote: 'J’ai été vraiment impressionnée par le travail de SAHEL GEO ! Tout au long du processus, ils nous ont tenus au courant de ce qu’ils faisaient et pourquoi et le résultat final est impressionnant. Merci pour l’excellent travail !'
        },
    ];

    return (
        <section id="testimonials" className="testimonials section-bg">
            <div className="container" data-aos="fade-up">
                <div className="section-header">
                    <h2>Témoignages</h2>
                    <p>Voici ce que nos clients disent de nous. Leur satisfaction est notre plus grande récompense.</p>
                </div>



                <Swiper
                    modules={[Navigation, Autoplay, Pagination]}
                    navigation={true}
                    pagination={{ clickable: true }}
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    className="mySwiper"
                >
                    {testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                    <div className="testimonial-wrap px-5">
                        <div className="testimonial-item d-flex align-items-center m-0">
                            <p>
                                 <i className="bi bi-quote quote-icon-left" />
                                {testimonial.quote}
                                <i className="bi bi-quote quote-icon-right" />
                            </p>
                            
                               
                        </div>
                    </div>
                    </SwiperSlide>
                    ))}
                </Swiper>



                
            </div>
        </section>
    );
};

export default Testimonials;