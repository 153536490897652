import { Routes, Route } from 'react-router-dom';
import { AddPost } from './pages/Admin/AddPost';
import { AddTag } from './pages/Admin/AddTag';
import { EditTag } from './pages/Admin/EditTag';
import { AddCategory } from './pages/Admin/AddCategory';
import { EditCategory } from './pages/Admin/EditCategory';
import { PostList } from './pages/Admin/PostList';
import { CategoryList } from './pages/Admin/CategoryList';
import { TagList } from './pages/Admin/TagList';
import { AdminLogin } from './pages/Admin/AdminLogin';
import { AddFaq } from './pages/Admin/AddFAQ';
import { FaqList } from './pages/Admin/FAQList';
import { EditFaq } from './pages/Admin/EditFAQ';
import { ViewPost } from './pages/Admin/ViewPost';

function Admin() {

  return (
    <div>
      <Routes>
        <Route path="/login" element={<AdminLogin />} />
        <Route path="add-post" element={<AddPost />} />
        <Route path="post/:id" element={<ViewPost />} />
        <Route path="tag" element={<AddTag />} />
        <Route path="tag/:id" element={<EditTag />} />
        <Route path="add-category" element={<AddCategory />} />
        <Route path="category/:id" element={<EditCategory />} />
        <Route path="/" element={<PostList />} index />
        <Route path="category-list" element={<CategoryList />} />
        <Route path="tag-list" element={<TagList />} />
        <Route path="add-faq" element={<AddFaq />} />
        <Route path="faq-list" element={<FaqList />} />
        <Route path="faq/:id" element={<EditFaq />} />
      </Routes>
    </div>
  );
}

export { Admin };