import React from 'react';
import { Link } from 'react-router-dom';

const CardBlog = ({id, imageUrl, date, title, author, category, content }) => {
  return (
    <div className="col-xl-4 col-md-6">
      <div className="post-item position-relative h-100">
        <div className="post-img position-relative overflow-hidden" style={{width: "416px", height:"312px"}}>
          {
            imageUrl ? (
              <img src={imageUrl} className="img-fluid" alt={title} style={{width: "416px", height:"312px", objectFit: "cover"}} />
            ) : (
              <img src="/assets/img/no-image.png" className="img-fluid" alt={title} style={{width: "416px", height:"312px"}} /> 
            )
          }
          <span className="post-date">{date}</span>
        </div>
        <div className="post-content d-flex flex-column">
          <h3 className="post-title">{title}</h3>
          <div className="meta d-flex align-items-center">
            <div className="d-flex align-items-center">
              {
                author == "Admin" ?
                  (
                    <>
                      <i className="bi bi-person" />
                      <span className="ps-2">SahelGéo</span>
                    </>
                  )
                  :
                  (
                    <>
                      <i className="bi bi-person" />
                      <span className="ps-2">{author}</span>
                    </>
                  )
              }
            </div>
            <span className="px-3 text-black-50">/</span>
            <div className="d-flex align-items-center">
              <i className="bi bi-folder2" /> <span className="ps-2">{category}</span>
            </div>
          </div>
          <hr />
          <Link to={`/blog-details/${id}`} className="readmore stretched-link">
            <span>Lire l'article</span><i className="bi bi-arrow-right" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CardBlog;
