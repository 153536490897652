import React from 'react';

const Header = () => {
    return (
        
        <footer id="admin-footer" className="admin-footer">
            <div className="copyright">
                © Copyright <strong><span>Infinity Spider Services</span></strong>. All Rights Reserved
            </div>
            
        </footer>


    );
};

export default Header;