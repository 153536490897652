import React from 'react';
import { Link } from 'react-router-dom';
import Faq from "react-faq-component";
import { getAllFaq } from '../features/faq/faqSlice';
import { useEffect ,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';



const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "black",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    //arrowIcon: "V",
    tabFocus: true
};

const FAQ = () => {
    const dispatch = useDispatch();
    const faqInfo = useSelector((state) => state.faq);
    const [isLoading, setIsLoading] = useState(true);

    console.log(useSelector((state) => state))

    useEffect(() => {
        dispatch(getAllFaq()).then(() => setIsLoading(false));


    }
        , [dispatch]);

    const [faqData, setFaqData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        const fetchFaqData = async () => {
            if (faqInfo && faqInfo.faqs && faqInfo.faqs.length > 0) {
                const faqdata = await Promise.all(faqInfo.faqs.map(async (faq) => {
                    return { title: faq.title, content: faq.content };
                }));
                setFaqData(faqdata);
            }
            setIsLoading(false);
        };

        fetchFaqData();
       
    }
        , [faqInfo.faqs]);

        console.log("faqData", faqData);

        const data = {
            title: "F.A.Q",
            rows: faqData,
        };
        

    return (
        isLoading ? <div id="preloader"></div> :
        <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>
            <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
            <h2>F.A.Q</h2>
            <ol>
                <li><Link to="/">Accueil</Link></li>
                <li>F.A.Q</li>
            </ol>
            </div>
        </div>{/* End Breadcrumbs */}
        {/* ======= Contact Section ======= */}
        <section id="faq" className="faq section-bg">
        <div class="" data-aos="fade-up" data-aos-delay="100">
        <Faq
                data={data}
                styles={styles}
                config={config}
            />
        </div>

        </section>{/* End Contact Section */}

        

        
        </main>

    );
};

export default FAQ;