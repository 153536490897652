import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { tagService } from './tagService';

export const getAllTag = createAsyncThunk(
    'tag/getAllTag',
    async () => {
        const response = await tagService.getTag()
        return response
    }
)

export const getTagById = createAsyncThunk(
    'tag/getTagById',
    async (id) => {
        const response = await tagService.getTagById(id)
        return response
    }
)

export const addTag = createAsyncThunk(
    'tag/addTag',
    async (data) => {
        const response = await tagService.createTag(data)
        return response
    }
)

export const updateTag = createAsyncThunk(
    'tag/updateTag',
    async (data) => {
        const response = await tagService.updateTag(data)
        return response
    }
)

export const deleteTag = createAsyncThunk(
    'tag/deleteTag',
    async (id) => {
        const response = await tagService.deleteTag(id)
        return response
    }
)

const initialState = {
    tags: [],
    tag: {},
    status: 'idle',
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
}

export const tagSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllTag.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllTag.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.tags = action.payload
            })
            .addCase(getAllTag.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to fetch tags')
            })
            .addCase(getTagById.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTagById.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.tag = action.payload
            })
            .addCase(getTagById.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to fetch tag')
            })
            .addCase(addTag.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addTag.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.tags.push(action.payload)
                toast.success('Tag added successfully')
            })
            .addCase(addTag.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to add tag')
            })
            .addCase(updateTag.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateTag.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.tags = state.tags.map(tag => {
                    if (tag.id === action.payload.id) {
                        return action.payload
                    }
                    return tag
                })
                toast.success('Tag updated successfully')
            })
            .addCase(updateTag.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to update tag')
            })
            .addCase(deleteTag.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteTag.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.tags = state.tags.filter(tag => tag.id !== action.payload.id)
                toast.success('Tag deleted successfully')
            })
            .addCase(deleteTag.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to delete tag')
            })
    }
})

export default tagSlice.reducer;