import axios from 'axios'
import { base_url, config, instance } from "../../utils/axiosconfig"

const createTag = async (data) => {
    try {
        const response = await instance.post(`${base_url}tag`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getTag = async () => {
    try {
        const response = await axios.get(`${base_url}tag`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getTagById = async (id) => {
    try {
        const response = await axios.get(`${base_url}tag/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const updateTag = async (data) => {
    try {
        const response = await instance.put(`${base_url}tag/${data.id}`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const deleteTag = async (id) => {
    console.log('id', id)
    try {
        const response = await instance.delete(`${base_url}tag/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const tagService = {
    getTag,
    getTagById,
    createTag,
    updateTag,
    deleteTag
}