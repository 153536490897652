import React from 'react';
import { Link } from 'react-router-dom';
import CardBlog from '../components/CardBlog';
import { getAllBlog } from '../features/blog/blogSlice';
import { getCategoryById } from '../features/category/categorySlice';
import { getTagById } from '../features/tag/tagSlice';
import { useEffect ,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr');


const Blog = () => {

    const dispatch = useDispatch();
    const blogInfo = useSelector((state) => state.blog);
    const { blogs } = blogInfo;


    useEffect(() => {
        dispatch(getAllBlog());

    }
        , [dispatch]);

    const [blogsData, setBlogsData] = useState([]);

    useEffect(() => {
        const fetchBlogsData = async () => {
            const blogsData = await Promise.all(blogs.map(async (blog) => {
                const category = await dispatch(getCategoryById(blog.category));
                return { ...blog, category: category.payload.title };
            }));
            setBlogsData(blogsData);
        };

        fetchBlogsData();
    }, [blogs, dispatch]);

    


    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>

                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Blog</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li>Blog</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                {/* ======= Blog Section ======= */}
                <section id="blog" class="blog">
                    <div class="container" data-aos="fade-up" data-aos-delay="100">
                        <div class="row gy-4 posts-list">
                            {blogsData.map((blog, index) => {
                                return (
                                    <CardBlog
                                        key={index}
                                        id={blog?._id}
                                        title={blog?.title}
                                        date={moment(blog?.createdAt).format('Do MMMM YYYY')}
                                        imageUrl={blog?.images[0]?.url}
                                        author={blog?.author}
                                        category={blog?.category}
                                        content={blog?.content}
                                    />
                                )
                            }
                            )}
                        </div>
                    </div>

                </section>{/* End Blog Section */}


            </main>{/* End #main */}


        </>
    );
};

export default Blog;