import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

   

    

    return (
        <>
            <div>
                <footer id="footer" className="footer">
                    <div className="footer-content position-relative">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6">
                                    <div className="footer-info">
                                        <h3>SahelGeo</h3>
                                        <p>
                                            Parcelles assainies Unité 9 <br />
                                            Rue CA 01, Dakar<br /><br />
                                            <strong>Téléphone:</strong> {"(+221) 33 835 78 14"}<br />
                                            <strong>Email:</strong> contact@sahelgeomatique.com<br />
                                        </p>
                                        <div className="social-links d-flex mt-3">
                                            <Link to="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://twitter.com/Sahelgeo&ved=2ahUKEwiPnYO8vp6FAxXzdqQEHWzbBXoQFnoECBEQAQ&usg=AOvVaw2sMXodhhsm4T0Yr8WbdS0x" className="d-flex align-items-center justify-content-center"><i className="bi bi-twitter" /></Link>
                                            <Link to="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.facebook.com/SahelGeo/%3Flocale%3Dfr_FR&ved=2ahUKEwjTyvDUvp6FAxUeTqQEHV2TCrcQFnoECBIQAQ&usg=AOvVaw1Iwy655v_FacZU3jCkcDnahttps://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.facebook.com/SahelGeo/%3Flocale%3Dfr_FR&ved=2ahUKEwjTyvDUvp6FAxUeTqQEHV2TCrcQFnoECBIQAQ&usg=AOvVaw1Iwy655v_FacZU3jCkcDna" className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook" /></Link>
                                            <Link to="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.instagram.com/sahel_geomatique/%3Fhl%3Dfr&ved=2ahUKEwiqm__1vp6FAxWZVqQEHQ5uBZ4QFnoECBoQAQ&usg=AOvVaw0TDxx7q4fWOp8DZn6sB5h4" className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram" /></Link>
                                            <Link to="https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://sn.linkedin.com/company/sahel-geomatique&ved=2ahUKEwiIuYSMv56FAxVZSaQEHeNSB58QFnoECA8QAQ&usg=AOvVaw1ZDs4tuMZcNCxHJHbeneWU" className="d-flex align-items-center justify-content-center"><i className="bi bi-linkedin" /></Link>
                                        </div>
                                    </div>
                                </div>{/* End footer info column*/}
                                <div className="col-lg-2 col-md-3 footer-links">
                                    <h4>Entreprise</h4>
                                    <ul>
                                        <li><Link to="/about">A propos de nous</Link></li>
                                        <li><Link to="/references">Nos Références</Link></li>
                                        <li><Link to="/contact">Nous contacter</Link></li>
                                        <li><Link to="/carriers">Carrières</Link></li>
                                        <li><Link to="/faq">F.A.Q</Link></li>
                                    </ul>
                                </div>{/* End footer links column*/}
                                <div className="col-lg-2 col-md-3 footer-links">
                                    <h4>Activités</h4>
                                    <ul>
                                        <li><Link to="/geomatic">Géomatique</Link></li>
                                        <li><Link to="/civil">Génie Civil</Link></li>
                                        <li><Link to="/agriculture">Agriculture et Précision</Link></li>
                                        <li><Link to="/rd">Recherche et Développement</Link></li>
                                        <li><Link to="/formation">Formation</Link></li>
                                    </ul>
                                </div>{/* End footer links column*/}
                                <div className="col-lg-4 col-md-6 footer-links">
                                    <h4>Emplacement SahelGeo</h4>
                                    <div style={{ width: "100%" }}>
                                        <iframe
                                            width="100%"
                                            height="300"
                                            frameBorder="0"
                                            marginHeight="0"
                                            marginWidth="0"
                                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Sahel%20G%C3%A9omatique%20Rue%20CA%2001,%20Dakar,%20Senegal+(Sahel%20G%C3%A9omatique%20)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                            title="SahelGeo Location">
                                        </iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-legal text-center position-relative">
                        <div className="container">
                            <div className="copyright">
                                © Copyright <strong><span>SahelGeo</span></strong>. All Rights Reserved
                            </div>
                            <div className="credits">
                                Powered by <a href="https://kmansour.netlify.app/">Infinity Spider Services</a>
                            </div>
                        </div>
                    </div>


                    <a href="#" class="scroll-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>

                    


                </footer>
                {/* End Footer */}
            </div>


        </>
    );
};

export default Footer;