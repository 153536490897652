import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { blogService } from './blogService';

export const getAllBlog = createAsyncThunk(
    'blog/getAllBlog',
    async () => {
        const response = await blogService.getBlog()
        return response
    }
)

export const getBlogById = createAsyncThunk(
    'blog/getBlogById',
    async (id) => {
        const response = await blogService.getBlogById(id)
        return response
    }
)

export const addBlog = createAsyncThunk(
    'blog/addBlog',
    async (data) => {
        console.log('data', data)
        const response = await blogService.createBlog(data)
        console.log('response', response)
        return response
    }
)

export const updateBlog = createAsyncThunk(
    'blog/updateBlog',
    async (data) => {
        const response = await blogService.updateBlog(data)
        return response
    }
)

export const deleteBlog = createAsyncThunk(
    'blog/deleteBlog',
    async (id) => {
        const response = await blogService.deleteBlog(id)
        return response
    }
)

export const getFilteredBlogs = createAsyncThunk(
    'blog/getFilteredBlogs',
    async (data) => {
        const response = await blogService.getFilteredBlogs(data)
        return response
    }
)

const initialState = {
    blogs: [],
    blog: {},
    blogImages: [],
    status: 'idle',
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
}

export const blogSlice = createSlice({
    name: 'blog',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllBlog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllBlog.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.blogs = action.payload
            })
            .addCase(getAllBlog.rejected, (state) => {
                state.isLoading = false
                state.isError = true
            })
            .addCase(getBlogById.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getBlogById.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.blog = action.payload
                console.log('action.payload', action.payload)
            })
            .addCase(getBlogById.rejected, (state) => {
                state.isLoading = false
                state.isError = true
            })
            .addCase(addBlog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addBlog.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload.message
                state.blogImages = action.payload.images;
                console.log("payload", action.payload)
                toast.success("Article ajouté avec succès", { autoClose: 2000 })
            })
            .addCase(addBlog.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                console.log('error', action.error)
                toast.error('Une erreur est survenue, veuillez réessayer', { autoClose: 2000 })
            })
            .addCase(updateBlog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateBlog.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload.message
                toast.success(action.payload.message, { autoClose: 2000})
            })
            .addCase(updateBlog.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Une erreur est survenue, veuillez réessayer', { autoClose: 2000 })
            })
            .addCase(deleteBlog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteBlog.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.message = action.payload.message
            })
            .addCase(deleteBlog.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Une erreur est survenue, veuillez réessayer', { autoClose: 2000 })
            })
            .addCase(getFilteredBlogs.pending, (state) => {
                state.isLoading = true
            }
            )
            .addCase(getFilteredBlogs.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.filteredBlog = action.payload.blogs
            })
            .addCase(getFilteredBlogs.rejected, (state) => {
                state.isLoading = false
                state.isError = true
            })

            
        }
},)


export default blogSlice.reducer;