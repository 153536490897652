import React from 'react';
import { Link } from 'react-router-dom';
import CardRef from '../components/CardRef';
import { references } from '../utils/refData';


  

const References = () => {


    return (
        <>
            <main id="main">
            {/* ======= Breadcrumbs ======= */}
    <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>
                <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                <h2>Références</h2>
                <ol>
                    <li><Link to="/">Accueil</Link></li>
                    <li>Références</li>
                </ol>
                </div>
            </div>{/* End Breadcrumbs */}
            
            
{/* ======= Servie Cards Section ======= */}
<section id="services-cards" className="services-cards">
  <div className="container" data-aos="fade-up">
    <div className="row gy-4">

        {references && references.map((ref, index) => {
            return (
                <CardRef 
                    key={index}
                    title={ref.title}
                    year={ref.year}
                    text={ref.text}
                    listItems={ref.listItems}
                />
            )
        }
        )}

      
    </div>
  </div>
</section>{/* End Servie Cards Section */}

            
            
            </main>{/* End #main */}


        </>
    );
};

export default References;



