import React from 'react';
import { Link } from 'react-router-dom';

const RD = () => {
    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>

                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Recherche et Développement</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li>Recherche et Développement</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                {/* ======= About Section ======= */}
                <section id="rd" className="rd">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-4">

                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">INSTRUMENTATION & SUIVI D’OUVRAGES</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Suivi d’ouvrages</h6>
                                            <p>Nous assurons le suivi et le contrôle de la qualité et de la pérennité de vos ouvrages (chemins de fer, Ponts, Digues, Barrages) en vous proposant un appui technique et un contrôle des caractéristiques des produits et des méthodes déployées.</p>
                                            <h6 className="fw-bold">Suivi de tassements sous remblais</h6>
                                            <p>Le tassement du sol est sa déformation verticale due à l’application des contraintes extérieures telles que les remblais, les fondations ou son propre poids. Les tassements peuvent être uniformes ou différents d’un point à l’autre selon la nature du sol en place. Nos compétences en géotechnique (profilométrie, tassométrie) seront mises à votre disposition pour le suivi des tassements.</p>
                                            <h6 className="fw-bold">Suivi de l’évolution de fissures par capteurs fissuromètres</h6>
                                            <p>Suivant la nature et les possibles évolutions des fissures, nous nous chargeons de faire le suivi, le bilan et proposer une intervention curative.</p>
                                            <h6 className="fw-bold">Mesure de déformation de façade par inclinomètre ou électro nivelles</h6>
                                            <p>Les mesures de rotations des parties d’ouvrages ou des sections dans le cadre de la surveillance permettent d’apprécier l’évolution dans le temps d’un phénomène anormal tel que les renversements et basculements (appuis, murs, avant ou arrière bec, garde-grève, etc.).</p>
                                            <h6 className="fw-bold">Suivi des niveaux de nappes phréatiques et de pression interstitielle</h6>
                                            <p>La mesure des pressions interstitielles et des niveaux piézométriques dans un sol se fait au moyen de piézomètres installés dans le cadre d’une surveillance géotechnique. La surveillance du niveau des nappes s’effectue en mesurant leur profondeur, c’est à dire l’épaisseur de la zone non saturée. Cette mesure s’effectue grâce à des dispositifs appelés piézomètres.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">INSPECTION DE CHAUSSÉES</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Profil longitudinal et Profils en travers</h6>
                                            <p>Pour tous vos projets, nous nous chargeons d’établir les profils en long, les profils en travers. Le profil en long d’une route est, avec le profil en travers et le tracé en plan, un des trois éléments qui permettent de caractériser la géométrie d’une route. Il est obtenu par élévation verticale dans le sens de l’axe de la route de l’ensemble des points constituant celui-ci.</p>
                                            <h6 className="fw-bold">Relevé des dégradations de surface</h6>
                                            <p>Le relevé des dégradations de surface est un élément de base de l’appréciation de l’état des chaussées. Pour établir un diagnostic et définir les opérations de remise en état, il peut quelquefois suffire à lui seul. Sinon l’information est à compléter par des résultats d’essais permettant de quantifier les caractéristiques mécaniques de la structure et les caractéristiques de surface.</p>
                                            <h6 className="fw-bold">Mesure Radar de chaussées</h6>
                                            <p>Ces dernières années, la technique d’auscultation radar est devenue une méthode à grand rendement. Nous utilisons cette technique couramment employée dans le domaine des chaussées pour la mesure des épaisseurs de couches traitées et non traitées.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">DEVELOPPEMENT APPLICATIONS & LOGICIELS</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Applications web appliquées (Géomatique et Génie civil)</h6>
                                            <p>Nous développons des logiciels de géomatique, de topographie, très simples d’emploi, qui permettent de dessiner des topographies de cavités à partir des valeurs relevées sur le terrain.</p>
                                            <h6 className="fw-bold">Applications Smartphone</h6>
                                            <p>Nous pouvons vous mettre en place une application qui vous permettra de faire un relevé topographique rapide avec une faible marge d’erreur et à faible coût. Elle récolte des informations sur le terrain sous formes de géométries (points, lignes ou polygones) associées à des données attributaires.</p>
                                            <h6 className="fw-bold">Logiciels gestion immobilière</h6>
                                            <p>Nous pouvons vous mettre en place un logiciel de gestion locative immobilière qui pourra assurer : le suivi des loyers et charges, la comptabilité, l’aide à la déclaration des revenus fonciers… Il pourra également automatiser la création de vos quittances et contrats de location… Pour chaque contrat de location, les loyers et les quittances électroniques sont générés automatiquement chaque mois.</p>
                                            <h6 className="fw-bold">Logiciels Calculs topométriques</h6>
                                            <p>SAHEL GEO offre des solutions tout en un qui intègrent et proposent l’ensemble des calculs topométriques et géodésiques. Nos logiciels calculent les positions à l’avancement et assurent la qualité de votre travail en donnant toute indication utile sur la précision de votre levé. Les logiciels réalisent une compensation par les moindres carrés à l’avancement ce qui permet d’avoir toujours une étude compensée.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12">
                                <div className="container" data-aos="fade-up">
                                    <div className="card custom-card">
                                        <div className='card-header text-center'>
                                            <h4 className="fw-bold">AUSCULTATION</h4>
                                        </div>
                                        <div className="card-body">
                                            <h6 className="fw-bold">Auscultation par topographie automatique</h6>
                                            <p>Cette technique que nous utilisons permet de mesurer les déformations subies par les ouvrages d’art (bâtiment, tunnel, barrage, chemin de fer, port…) et les structures naturelles (cavité souterraine, falaise…) en temps réel.</p>
                                            <h6 className="fw-bold">Auscultation de fondations</h6>
                                            <p>Nos équipes disposent du savoir-faire et d’un panel important de matériels d’instrumentation et d’auscultation dans l’accompagnement des acteurs de la construction pour la caractérisation, le suivi et la réception des ouvrages en cours de chantier. Nous faisons l’auscultation d’éléments de fondation : pieu béton armé ou non, barrette, paroi moulée, inclusion rigide, pieu métallique, palplanche, micropieu, etc. Ce qui nous permet de contrôler ou d’évaluer leurs caractéristiques, leur intégrité et leur portance.</p>
                                            <h6 className="fw-bold">Détection d’endommagement interne de matériaux</h6>
                                            <p>Dans le domaine des transports (ferroviaires, automobiles, aéronautiques), l’accroissement de l’utilisation des matériaux composites et le renforcement des normes de sécurité conduisent à l’obligation d’un contrôle périodique accru des structures. Ce contrôle a notamment pour objectif d’en prévenir toute défaillance due à une perte de ses caractéristiques mécaniques induites par de l’endommagement.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>



            </main>{/* End #main */}


        </>
    );
};

export default RD;