import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { getBlogById, getFilteredBlogs } from '../features/blog/blogSlice';
import { getCategoryById, getAllCategory } from '../features/category/categorySlice';
import { getTagById, getAllTag } from '../features/tag/tagSlice';
import { addComment } from '../features/comment/commentSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';
import { useNavigate } from 'react-router-dom';




export const BlogDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const blogInfo = useSelector((state) => state.blog);
    const { blog } = blogInfo;

    const categories = useSelector((state) => state.category.categories);
    const allTags = useSelector((state) => state.tag.tags);

    const [isLoading, setIsLoading] = useState(true);

    const [category, setCategory] = useState('');
    const [tags, setTags] = useState([]);

    const filteredBlogs = useSelector((state) => state.blog.filteredBlog);

    useEffect(() => {
        setIsLoading(true);
        dispatch(getBlogById(id)).then(() => {
            setIsLoading(false);
        });
        dispatch(getAllCategory());
        dispatch(getAllTag());
        dispatch(getFilteredBlogs({ limit: 5 }));

    }, [dispatch, id]);

    useEffect(() => {
        const fetchData = async () => {
            if (blog && blog?.tags && blog?.tags.length > 0) {
                const categoryResponse = await dispatch(getCategoryById(blog?.category));
                if (categoryResponse.payload) {
                    setCategory(categoryResponse?.payload?.title);
                }
                const tagsResponse = await Promise.all(blog.tags.map(tagId => dispatch(getTagById(tagId))));
                if (tagsResponse && tagsResponse.length > 0 && tagsResponse[0].payload !== undefined){
                    setTags(tagsResponse.map(tag => tag?.payload?.title));
                }
            }
        };
        fetchData();
    }, [dispatch, blog]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [comment, setComment] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {  
        setEmail(e.target.value);
    };

    const handleWebsiteChange = (e) => {
        setWebsite(e.target.value);
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };
   
    const handleComment = (e) => {
        e.preventDefault();
        const data = {
            blogId: blog?._id,
            name: e.target.name.value,
            email: e.target.email.value,
            website: e.target.website.value,
            text: e.target.comment.value,
        }
        dispatch(addComment(data)).then(() => {
            dispatch(getBlogById(id))
        })
    }

    const [filterOptions, setFilterOptions] = useState({});

    useEffect(() => {
        if (Object.keys(filterOptions).length > 0) {
            navigate('/filter', { state: { filterOptions } });
        }
    }, [filterOptions, navigate]);
    
    const handleCategoryClick = (category) => {
        setFilterOptions({ category: category });
    };

    const handleTagClick = (tag) => {
        setFilterOptions({ tag: tag });
    };

    const [search, setSearch] = useState('');

    const handleSearch = (event) => {
        event.preventDefault();
        setFilterOptions({ name: search });
    };

     


    if (isLoading) {
        return (
            <div className="container">
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Blog</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li>Blog</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                <div>
                    <section id="blog" className="blog">
                        <div className="container" data-aos="fade-up" data-aos-delay={100}>
                            <div className="row g-5">
                                <div className="col-lg-8">
                                    <article className="blog-details">
                                        {blog && (
                                            <div className="post-img">
                                                {
                                                    blog?.images[0]?.url ? 
                                                   ( <img src={blog?.images[0]?.url} className="img-fluid" alt="" style={{ width: "848px", height: "636px" ,objectFit: "cover" }} /> ): 
                                                   ( <img src="/assets/img/no-image.png" className="img-fluid" alt="" style={{ width: "848px", height: "636px" ,objectFit: "cover" }} /> )
                                                }
                                            </div>
                                        )}
                                        <h2 className="title">{blog?.title}</h2>
                                        <div className="meta-top">
                                            <ul>
                                                <li className="d-flex align-items-center"><i className="bi bi-person" /> <a href="blog-details.html">
                                                    {blog?.author === "Admin" ? "SahelGéo" : blog?.author}
                                                    </a></li>
                                                <li className="d-flex align-items-center"><i className="bi bi-clock" /> <a href="blog-details.html">{moment(blog?.createdAt).format('Do MMMM YYYY')}</a></li>
                                            </ul>
                                        </div>{/* End meta top */}
                                        <div className="content">
                                        <p dangerouslySetInnerHTML={{ __html: blog?.content }}></p>
                                        </div>{/* End post content */}
                                        <div className="meta-bottom">
                                            <i className="bi bi-folder" />
                                            <ul className="cats">
                                                <li><a href="#">{category}</a></li>
                                            </ul>
                                            <i className="bi bi-tags" />
                                            <ul className="tags">
                                                {
                                                    tags && tags?.map((tag, index) => (
                                                        <li key={index}><a href="#">{tag}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>{/* End meta bottom */}
                                    </article>{/* End blog post */}
                                    
                                    <div className="comments">
                                       {
                                             blog?.comments && blog.comments.map((comment, index) => (
                                                  <div className="comment" key={index}>
                                                    <div className="d-flex">
                                                         <div className="comment-img"><img src="/assets/img/blog/avatar.png" alt="" style={{width: "30px"}} /></div>
                                                         <div>
                                                              <h4>{comment?.name}</h4>
                                                              <time dateTime="2020-01-01">{moment(comment?.createdAt).format('Do MMMM YYYY')}</time>
                                                         </div>
                                                    </div>
                                                    <p>
                                                         {comment?.text}
                                                    </p>
                                                  </div>
                                             ))
                                       }
                                        <div className="reply-form">
                                            <h4>Laisser un Commentaire</h4>
                                            <p>Ton adresse mail ne sera pas publié. Les champs obligatoire son marqués par (*)</p>
                                            <form onSubmit={handleComment}>
                                                <div className="row">
                                                    <div className="col-md-6 form-group">
                                                        <input name="name" type="text" className="form-control" placeholder="Votre nom*" value={name} onChange={handleNameChange} />
                                                    </div>
                                                    <div className="col-md-6 form-group">
                                                        <input name="email" type="text" className="form-control" placeholder="Votre Email*" value={email} onChange={handleEmailChange} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col form-group">
                                                        <input name="website" type="text" className="form-control" placeholder="Votre Site Web" value={website} onChange={handleWebsiteChange} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col form-group">
                                                        <textarea name="comment" className="form-control" placeholder="Votre Commentaire*" defaultValue={""} value={comment} onChange={handleCommentChange} />
                                                    </div>
                                                </div>
                                                <button type="submit" className="btn btn-primary" >Poster votre commentaire</button>
                                            </form>
                                        </div>
                                    </div>{/* End blog comments */}
                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar">
                                        <div className="sidebar-item search-form">
                                            <h3 className="sidebar-title">Rechercher</h3>
                                            <form onSubmit={handleSearch} className="mt-3">
                                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                <button type="submit"><i className="bi bi-search" /></button>
                                            </form>
                                        </div>{/* End sidebar search formn*/}
                                        <div className="sidebar-item categories">
                                            <h3 className="sidebar-title">Catégories</h3>
                                            <ul className="mt-3">
                                                {
                                                    categories && categories.map((category, index) => (
                                                        <button className='btn' onClick={() => handleCategoryClick(category._id)}><li key={index}><a>{category.title}</a></li></button>
                                                    ))
                                                }
                                            </ul>
                                        </div>{/* End sidebar categories*/}
                                        <div className="sidebar-item recent-posts">
                                            <h3 className="sidebar-title">Articles récents</h3>
                                            {
                                                filteredBlogs && filteredBlogs.map((blog, index) => (
                                                    <div className="post-item" key={index}>
                                                        {
                                                            blog?.images[0]?.url ? <img src={blog?.images[0]?.url} alt="" style={{width: "80px", height:"80px", objectFit:"cover"}} /> : <img src="/assets/img/no-image.png" alt="" style={{width: "80px", height:"80px", objectFit:"cover"}} />
                                                        }
                                                        <div>
                                                            <h4><Link to={`/blog-details/${blog.id}`}>{blog.title}</Link></h4>
                                                            <time dateTime="2020-01-01">{moment(blog.createdAt).format('Do MMMM YYYY')}</time>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                           
                                        </div>{/* End sidebar recent posts*/}
                                        <div className="sidebar-item tags">
                                            <h3 className="sidebar-title">Tags</h3>
                                            <ul className="mt-3">
                                                {
                                                    allTags && allTags.map((tag, index) => (
                                                        <li key={index}><a onClick={() => handleTagClick(tag._id)}>{tag.title}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>{/* End sidebar tags*/}
                                    </div>{/* End Blog Sidebar */}
                                </div>
                            </div>
                        </div>
                    </section>{/*End Blog Details Section-- >
*/}</div>


            </main>{/* End #main */}


        </>
    );
};


export default BlogDetail;