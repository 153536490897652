import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { commentService } from './commentService';

export const getAllComment = createAsyncThunk(
    'comment/getAllComment',
    async () => {
        const response = await commentService.getComment()
        return response
    }
)

export const getCommentById = createAsyncThunk(
    'comment/getCommentById',
    async (id) => {
        const response = await commentService.getCommentById(id)
        return response
    }
)

export const addComment = createAsyncThunk(
    'comment/addComment',
    async (data) => {
        const response = await commentService.createComment(data)
        return response
    }
)

export const updateComment = createAsyncThunk(
    'comment/updateComment',
    async (data) => {
        const response = await commentService.updateComment(data)
        return response
    }
)

export const deleteComment = createAsyncThunk(
    'comment/deleteComment',
    async (id) => {
        const response = await commentService.deleteComment(id)
        return response
    }
)

const initialState = {
    comments: [],
    comment: {},
    status: 'idle',
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
}

export const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllComment.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getAllComment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.comments = action.payload
            })
            .addCase(getAllComment.rejected, (state) => {
                state.isLoading = false
                state.isError = true
            })
            .addCase(getCommentById.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(getCommentById.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.comment = action.payload
            })
            .addCase(getCommentById.rejected, (state) => {
                state.isLoading = false
                state.isError = true
            })
            .addCase(addComment.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(addComment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.comments.push(action.payload)
                console.log('ajout commentaire', action.payload)
                toast.success('Comment added successfully')
            })
            .addCase(addComment.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to add comment')
            })
            .addCase(updateComment.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(updateComment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.comments = state.comments.map(comment => {
                    if (comment.id === action.payload.id) {
                        return action.payload
                    }
                    return comment
                })
                toast.success('Comment updated successfully')
            })
            .addCase(updateComment.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to update comment')
            })
            .addCase(deleteComment.pending, (state) => {
                state.isLoading = true
                state.isError = false
                state.isSuccess = false
            })
            .addCase(deleteComment.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.comments = state.comments.filter(comment => comment.id !== action.payload.id)
                toast.success('Comment deleted successfully')
            })
            .addCase(deleteComment.rejected, (state) => {
                state.isLoading = false
                state.isError = true
                toast.error('Failed to delete comment')
            })
    }
})

export default commentSlice.reducer