import React from 'react';
import { AdminLayout } from './AdminLayout';
import { addCategory } from '../../features/category/categorySlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';


const AddCategory = () => {
    const dispatch = useDispatch();
    const [categoryName, setCategoryName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(addCategory({title: categoryName}));
        setCategoryName('');
    };

    return (

        <AdminLayout>
            <section className="section p-0" style={{height: "80vh"}}>
                <div className="row" style={{width: "88%", height: "100%"}}>
                    <div className="col-lg-12 me-2" style={{height: "100%"}}>
                        <div className="card" style={{height: "100%"}}>
                            <div className="card-body" style={{height: "100%"}}>
                                <h3 className="admin-card-title">Ajouter une Catégorie</h3>
                                <form onSubmit={handleSubmit} style={{height: "100%"}}>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Nom de la Catégorie</label>
                                        
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
                                        </div>
                                    </div>
                                   
                                   

                                   
                                    <div className="row mb-3">
                                        <div className="col-sm-10 w-100 d-flex justify-content-end me-5">
                                            <button type="submit" className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

        </AdminLayout>
    );
};

export { AddCategory };


