import React from 'react';
import { AdminLayout } from './AdminLayout';
import { MyDataTable } from './components/DataTable';

const PostList = () => {
    return (
        <AdminLayout>
            <section className="section p-0">
                <div className="row post-list-card" style={{width: "88%"}}>
                    <div className="col-lg-12 me-2">
                        <div className="card">
                            <div className="card-body">
                                <h3 className="admin-card-title">Liste des Articles</h3>
                                <MyDataTable />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </AdminLayout>
    );
};

export { PostList };