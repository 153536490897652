import React from 'react';
import { Link } from 'react-router-dom';
import Header from './AdminHeader';
import Footer from './AdminFooter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';

const AdminLayout = ({ children }) => {
    const location = useLocation();
    return (
        <div>
            <Header />
            <div className="container p-0 m-0">
                <div className="row">

                    <aside id="sidebar" className="sidebar">
                        <ul className="sidebar-nav" id="sidebar-nav">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin' ? '' : 'collapsed'}`} to="/admin">
                                    <i className="bi bi-grid" />
                                    <span>Blogs</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin/add-post' ? '' : 'collapsed'}`} to="/admin/add-post">
                                    <i class="bi bi-plus-square"></i>
                                    <span>Ajouter un article</span>
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin/tag-list' ? '' : 'collapsed'}`} to="/admin/tag-list">
                                    <i class="bi bi-bookmarks"></i>
                                    <span>Tags</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin/tag' ? '' : 'collapsed'}`} to="/admin/tag">
                                    <i class="bi bi-bookmark-plus"></i>
                                    <span>Ajouter un Tag</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin/category-list' ? '' : 'collapsed'}`} to="/admin/category-list">
                                    <i class="bi bi-folder2"></i>
                                    <span>Catégories</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin/add-category' ? '' : 'collapsed'}`} to="/admin/add-category">
                                    <i class="bi bi-folder-plus"></i>
                                    <span>Ajouter une Catégorie</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin/faq-list' ? '' : 'collapsed'}`} to="/admin/faq-list">
                                    <i class="bi bi-patch-question"></i>
                                    <span>Liste des Questions</span>
                                </Link>
                            </li>

                            <li class="nav-item">
                                <Link className={`nav-link ${location.pathname === '/admin/add-faq' ? '' : 'collapsed'}`} to="/admin/add-faq">
                                    <i class="bi bi-question-octagon"></i>
                                    <span>Ajouter une Question</span>
                                </Link>
                            </li>
                        </ul>
                    </aside>

                    <main id="main" class="main">
                        {children}
                    </main>

                </div>
            </div>
            <Footer />
            <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            theme="light"
            />
        </div>



    );
};

export {AdminLayout};