import React, { useState, useEffect } from 'react';
import { loginUser } from '../../features/user/userSlice.js';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
   
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(loginUser({ email, password }));
    };

    useEffect(() => {
        import('./assets/style.css');
        import('./assets/admin.js');
      }, []);


      const userLogin = useSelector((state) => state.user);
      const { user, isLoading, isError, isSuccess } = userLogin;

      useEffect(() => {
        if (isSuccess) {
           window.location.href = '/admin';
        }
        else {
           navigate('');
        }
     }, [user, isError, isSuccess, isLoading])


    return (
<main>
  <div className="container">
    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex justify-content-center py-4">
              <a href="#" className="logo d-flex align-items-center w-auto">
                <span className="d-none d-lg-block">Sahel Géomatique</span>
              </a>
            </div>{/* End Logo */}
            <div className="card mb-3">
              <div className="card-body">
                <div className="pt-4 pb-2">
                  <h5 className="card-title text-center pb-0 fs-4">Connexion à SahelGéo Admin</h5>
                  <p className="text-center small">Veuillez entrer les informations de connexion</p>
                </div>
                <form className="row g-3 needs-validation" onSubmit={handleSubmit}>
                  <div className="col-12">
                    <label htmlFor="yourUsername" className="form-label">Email</label>
                    <div className="input-group has-validation">
                      <input type="email" name="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)}   required />
                      <div className="invalid-feedback">Veuillez entrer votre adresse Email.</div>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="yourPassword" className="form-label">Mot de passe</label>
                    <input type="password" name="password" className="form-control" id="yourPassword" value={password} onChange={(e) => setPassword(e.target.value)}  required />
                    <div className="invalid-feedback">Veuillez entrer votre Mot de Passe</div>
                  </div>
                  <div className="col-12">
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" name="remember" defaultValue="true" id="rememberMe" />
                      <label className="form-check-label" htmlFor="rememberMe">Se Souvenir de Moi</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">Connexion</button>
                  </div>
                  
                </form>
              </div>
            </div>
            <div className="credit">
                <span>Infinity Spider Services</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>

    );
};

export { AdminLogin };