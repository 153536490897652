import React, { useState } from 'react';

const CardRef = ({ title, year, text, listItems }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const showMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="col-lg-3 col-md-6 ref-card" data-aos="zoom-in" data-aos-delay={100}>
      <div className='inside-ref-card'>
      <h3>{title}</h3>
      <div>
       <div className='text-ref'>
       <p><b>{year}</b>: {text}</p>
       </div>
        <ul className={`awr ${isExpanded ? 'show' : ''}`} id='awr1'>
          { listItems && listItems.map((item, index) => (
            <li key={index}><i className="bi bi-check2" /> <span>{item}</span></li>
          ))}
        </ul> 

        <button className="btn readmore stretched-link " onClick={showMore}>
          {isExpanded ? 'Voir moins ' : 'Voir plus '}
          {isExpanded ? <i className="bi bi-dash-circle"></i> : <i className="bi bi-plus-circle"></i>}
        </button>

      </div>
      </div>
    </div>
  );
};

export default CardRef;