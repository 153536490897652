import axios from 'axios'
import { base_url, config, instance } from "../../utils/axiosconfig"

const createCategory = async (data) => {
    try {
        const response = await instance.post(`${base_url}category`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getCategory = async () => {
    try {
        const response = await axios.get(`${base_url}category`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getCategoryById = async (id) => {
    try {
        const response = await axios.get(`${base_url}category/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const updateCategory = async (data) => {
    try {
        const response = await instance.put(`${base_url}category/${data.id}`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const deleteCategory = async (id) => {
    try {
        const response = await instance.delete(`${base_url}category/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const categoryService = {
    getCategory,
    getCategoryById,
    createCategory,
    updateCategory,
    deleteCategory
}