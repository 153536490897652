import axios from 'axios'
import { base_url, config } from "../../utils/axiosconfig"

const createUser = async (data) => {
    try {
        
        const response = await axios.post(`${base_url}user`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const loginUser = async (data) => {
    try {
        console.log("data", data)
        const response = await axios.post(`${base_url}user/login`, data, config)
        if( response.data ){
            localStorage.setItem('user', JSON.stringify(response.data))
        }
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getUser = async () => {
    try {
        const response = await axios.get(`${base_url}user`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getUserById = async (id) => {
    try {
        const response = await axios.get(`${base_url}user/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const updateUser = async (data) => {
    try {
        const response = await axios.put(`${base_url}user/${data.id}`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const deleteUser = async (id) => {
    try {
        const response = await axios.delete(`${base_url}user/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const logoutUser = async () => {
    try {
        const response = await axios.get(`${base_url}user/logout`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const userService = {
    getUser,
    loginUser,
    getUserById,
    createUser,
    updateUser,
    deleteUser,
    logoutUser
}
