import React from 'react';
import { AdminLayout } from './AdminLayout';
import { updateTag } from '../../features/tag/tagSlice';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getTagById } from '../../features/tag/tagSlice';





const EditTag = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [tagName, setTagName] = useState('');

    const getTagId = location.pathname.split('/')[3];

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateTag({title: tagName, id: getTagId}));
        setTagName('');
    };

    useEffect(() => {
        const fetchTag = async () => {
            if (getTagId) {
                const tag = await dispatch(getTagById(getTagId));
                setTagName(tag.payload.title);
            }
        };
        fetchTag();
    }, [dispatch, getTagId]);

    return (

        <AdminLayout>
            <section className="section p-0" style={{height: "80vh"}}>
                <div className="row" style={{width: "88%", height: "100%"}}>
                    <div className="col-lg-12 me-2" style={{height: "100%"}}>
                        <div className="card" style={{height: "100%"}}>
                            <div className="card-body" style={{height: "100%"}}>
                                <h3 className="admin-card-title">
                                    {getTagId ? "Modifier le Tag" : "Ajouter un Tag"}
                                </h3>
                                <form onSubmit={handleSubmit} style={{height: "100%"}}>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-2 col-form-label">Nom du Tag</label>
                                        
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" value={tagName} onChange={(e) => setTagName(e.target.value)} />
                                        </div>
                                    </div>
                                   
                                   

                                   
                                    <div className="row mb-3">
                                        <div className="col-sm-10 w-100 d-flex justify-content-end me-5">
                                            <button type="submit" className="btn btn-primary">Enregistrer</button>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            

        </AdminLayout>
    );
};

export { EditTag };


