import React from 'react';
import { Link } from 'react-router-dom';

const Carriers = () => {
    return (
        <>
            <main id="main">
            {/* ======= Breadcrumbs ======= */}
    <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>
                <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                <h2>Carrières</h2>
                <ol>
                    <li><Link to="/">Accueil</Link></li>
                    <li>Carrières</li>
                </ol>
                </div>
            </div>{/* End Breadcrumbs */}
           


{/* ======= Get Started Section ======= */}
<section id="get-started" className="get-started section-bg">
  <div className="container">
    <div className="row justify-content-between gy-4">
      <div className="col-lg-6 d-flex align-items-center" data-aos="fade-up">
        <div className="content">
          <h3>Carrière chez Sahel Géomatique</h3>
          <p>Chez Sahel Géomatique, nous croyons en l'investissement dans le développement de nos employés. Nous offrons des opportunités de formation continue, des programmes de mentorat et des possibilités d'avancement professionnel pour permettre à nos employés d'atteindre leur plein potentiel.
          </p><p>Si vous êtes intéressé(e) à rejoindre notre équipe, veuillez prendre contact avec nous à travers le formulaire. Nous vous remercions de l'intérêt que vous portez à Sahel Géomatique et nous avons hâte de découvrir votre candidature.</p>
        </div>
      </div>
      <div className="col-lg-5" data-aos="fade">
        <form action="" method="post" className="php-email-form">
          <h3>Postulez chez Sahel Géomatique</h3>
          <p>Veuillez fournir vos informations et détailler votre message dans les champs ci-dessous.</p>
          <div className="row gy-3">
            <div className="col-md-12">
              <input type="text" name="name" className="form-control" placeholder="Nom" required />
            </div>
            <div className="col-md-12 ">
              <input type="email" className="form-control" name="email" placeholder="Email" required />
            </div>
            
            <div className="col-md-12">
              <textarea className="form-control" name="message" rows={6} placeholder="Message" required defaultValue={""} />
            </div>
            <div className="col-md-12 text-center">
              <div className="loading">Loading</div>
              <div className="error-message" />
              <div className="sent-message">Your message has been sent successfully. Thank you!</div>
              <button type="submit">Envoyer</button>
            </div>
          </div>
        </form>
      </div>{/* End Quote Form */}
    </div>
  </div>
</section>{/* End Get Started Section */}

            
            
            
            </main>{/* End #main */}


        </>
    );
};

export default Carriers;