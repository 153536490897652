import axios from "axios";
import { useNavigate } from "react-router-dom";
import  { useEffect } from "react";

//export const base_url = "http://localhost:5001/api/";
export const base_url = "https://sahelgeo-backend-coyf.onrender.com/api/";



export const instance = axios.create({
    baseURL: base_url, 
    timeout: 10000, 
  });

  const getTokenFromLocalStorage = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null


  export const config = {
    headers: {
       'Authorization': `Bearer ${getTokenFromLocalStorage !== null ? getTokenFromLocalStorage.token : ""}`,
       'Accept': 'application/json'
    }
 }
 
 
 instance.interceptors.response.use(
   response => response,  // Continuer normalement pour les réponses réussies
   error => {
     if (error.response.status === 401) {
       // Déconnexion de l'utilisateur ici
       // Supprimer les informations d'authentification dans le local storage et rediriger vers la page de connexion
       localStorage.removeItem('user');
       window.location.href = '/admin/login';  // Redirection vers la page de connexion
     }
     return Promise.reject(error);
   }
 );