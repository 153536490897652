import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import { categoryService } from './categoryService';

export const getAllCategory = createAsyncThunk(
    'category/getAllCategory',
    async () => {
        const response = await categoryService.getCategory()
        return response
    }
)

export const getCategoryById = createAsyncThunk(
    'category/getCategoryById',
    async (id) => {
        const response = await categoryService.getCategoryById(id)
        return response
    }
)

export const addCategory = createAsyncThunk(
    'category/addCategory',
    async (data) => {
        const response = await categoryService.createCategory(data)
        return response
    }
)   

export const updateCategory = createAsyncThunk(
    'category/updateCategory',
    async (data) => {
        const response = await categoryService.updateCategory(data)
        return response
    }
)

export const deleteCategory = createAsyncThunk(
    'category/deleteCategory',
    async (id) => {
        const response = await categoryService.deleteCategory(id)
        return response
    }
)

const initialState = {
    categories: [],
    category: {},
    status: 'idle',
    isError: false,
    isLoading: false,
    isSuccess: false,
    message: '',
}

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllCategory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.categories = action.payload
            })
            .addCase(getAllCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
            })
            .addCase(getCategoryById.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCategoryById.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.category = action.payload
            })
            .addCase(getCategoryById.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
            })
            .addCase(addCategory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.categories.push(action.payload)
                toast.success('Category added successfully')
            })
            .addCase(addCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
                toast.error('Failed to add category')
            })
            .addCase(updateCategory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.categories = state.categories.map(category => { return category.id === action.payload.id ? action.payload : category })
                toast.success('Category updated successfully')
            }
            )
            .addCase(updateCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
                toast.error('Failed to update category')
            })
            .addCase(deleteCategory.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.categories = state.categories.filter(category => category.id !== action.payload.id)
                toast.success('Category deleted successfully')
            })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.error.message
                toast.error('Failed to delete category')
            })
    }
})

export default categorySlice.reducer