import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>

                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>A propos</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li>A propos</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                {/* ======= About Section ======= */}
                <section id="about" className="about">
                    <div class="container" data-aos="fade-up">

                        <div className="row position-relative">
                            <div className="col-lg-7 about-img" style={{ backgroundImage: 'url(assets/img/human-c3.jpeg)' }} />
                            <div className="col-lg-7">
                                <h2>Découvrez Sahel Géomatique</h2>
                                <div className="our-story">
                                    <h4>Depuis 1992</h4>
                                    <h3>Histoire de l'entreprise</h3>
                                    <p>Sahel Géomatique fut créée par M. EL Hadj Moustaph MBAYE, Maitre en science géodésique, ingénieur topographe et photogrammètre diplômé de l’École Nationale des Travaux Publics (ENTPB), de l’Institut International des levés aériens et sciences   de la Terre(ITC) à la Hollande et de la faculté de foresterie et géomatique de l’Université LAVAL au Canada.
                                        Membre de l’Ordre des Géomètres Expert, il s’était distingué par sa rigueur, la qualité de ses travaux et son intégrité dans le travail. </p>
                                    <p>Sous la Direction d’un Ingénieur Polytechnicien et d’un Géomètre Expert depuis 2018, Sahel Géomatique a su renforcer tout son potentiel par l’acquisition de matériels performants de dernière génération mais également le recrutement de collaborateurs hautement qualifiés afin d'assurer une satisfaction totale à ses clients.
                                        Durant plus d’une vingtaine d’année de présence sur le marché sénégalais, Sahel Géomatique a participé à plusieurs projets de grande envergure sur l’ensemble du territoire national.
                                    </p>

                                </div>
                            </div>
                        </div>


                    </div>
                </section>

                {/* ======= Features Section ======= */}
                <section id="features" className="features section-bg">
                    <div className="container" data-aos="fade-up">
                            <div className="tab-pane active show">
                                <div className="row">
                                    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay={100}>
                                        <h3>Mission de l'entreprise</h3>
                                        <p className="fst-italic">
                                        Sahel Géomatique a pour mission de fournir des solutions de cartographie, de mesure et d'analyse géo spatiale de haute précision. Nous nous engageons à aider nos clients à mieux comprendre leur environnement en utilisant des technologies de pointe telles que les systèmes de positionnement global (GPS), les drones, et les logiciels SIG (Systèmes d'Information Géographique). Notre objectif est de contribuer à la planification urbaine, à la gestion des ressources naturelles, et à l'amélioration de l'efficacité des infrastructures en fournissant des données géospatiales fiables et précises.
                                        </p>
                                        
                                    </div>
                                    <div className="col-lg-6 order-1 order-lg-2 text-center" data-aos="fade-up" data-aos-delay={200}>
                                        <img src="assets/img/human-c4.jpg" alt className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>{/* End Features Section */}

                {/* ======= Alt Services Section ======= */}
                <section id="alt-services" className="alt-services">
                    <div className="container" data-aos="fade-up">
                        <div className="row justify-content-around gy-4">
                            <div className="col-lg-6 img-bg" style={{ backgroundImage: 'url(assets/img/human-c.jpeg)' }} data-aos="zoom-in" data-aos-delay={100} />
                            <div className="col-lg-5 d-flex flex-column justify-content-center">
                                <h3>Valeurs de l’entreprise</h3>
                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={100}>
                                    <i className="bi bi-boxes flex-shrink-0" />
                                    <div>
                                        <h4><a href className="stretched-link">Précision </a></h4>
                                        <p>Engagement envers l'excellence, qualité exceptionnelle dans chaque projet réalisé.</p>
                                    </div>
                                </div>{/* End Icon Box */}
                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={200}>
                                    <i className="bi bi-patch-check flex-shrink-0" />
                                    <div>
                                        <h4><a href className="stretched-link">Intégrité</a></h4>
                                        <p>Éthique, transparence, relations de confiance avec clients et partenaires.</p>
                                    </div>
                                </div>{/* End Icon Box */}
                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={300}>
                                    <i className="bi bi-graph-up-arrow flex-shrink-0" />
                                    <div>
                                        <h4><a href className="stretched-link">Innovation</a></h4>
                                        <p>À la pointe, solutions créatives, embrasser les dernières avancées technologiques.</p>
                                    </div>
                                </div>{/* End Icon Box */}
                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={400}>
                                    <i className="bi bi-people flex-shrink-0" />
                                    <div>
                                        <h4><a href className="stretched-link">Collaboration</a></h4>
                                        <p>Travail d'équipe harmonieux, combinaison de compétences pour solutions innovantes.</p>
                                    </div>
                                </div>{/* End Icon Box */}
                                <div className="icon-box d-flex position-relative" data-aos="fade-up" data-aos-delay={400}>
                                    <i className="bi bi-brightness-high flex-shrink-0" />
                                    <div>
                                        <h4><a href className="stretched-link">Responsabilité environnementale</a></h4>
                                        <p>Minimiser l'empreinte écologique, préserver et protéger notre planète.</p>
                                    </div>
                                </div>{/* End Icon Box */}
                            </div>
                        </div>
                    </div>
                </section>{/* End Alt Services Section */}





            </main>{/* End #main */}


        </>
    );
};

export default About;