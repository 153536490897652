import React, { useRef, useEffect } from 'react';

import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableColumnType,
    TableHeader
  } from "react-bs-datatable";
  import { Button, Col, Row, Table } from "react-bootstrap";  
  import 'bootstrap/dist/css/bootstrap.min.css';
  import { getAllBlog } from '../../../features/blog/blogSlice';
  import { useDispatch, useSelector } from 'react-redux';
  import { getCategoryById } from '../../../features/category/categorySlice';
  import { useState } from 'react';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';


  const STORY_HEADERS = [
    {
      prop: "number",
      title: "#",
    },
    {
      prop: "title",
      title: "Titre",
      isFilterable: true
    },
    {
      prop: "Category",
      title: "Catégorie",
    },
    {
      prop: "date",
      title: "Date de Création",
    },
    {
      prop: "button",
      cell: (row) => (
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() =>  window.location.href = `/admin/post/${row.id}`}
        >
          Voir le Post
        </Button>
      )
    }
  ];

  






const MyDataTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const blogInfo = useSelector((state) => state.blog);
  const { blogs } = blogInfo;

  useEffect(() => {
      dispatch(getAllBlog());

  }
      , [dispatch]);

  const [blogsData, setBlogsData] = useState([]);

  useEffect(() => {
      const fetchBlogsData = async () => {
          const blogsData = await Promise.all(blogs.map(async (blog) => {
              const category = await dispatch(getCategoryById(blog.category));
              return { ...blog, category: category.payload.title };
          }));
          setBlogsData(blogsData);
      }
      fetchBlogsData();
  }, [blogs, dispatch]);

  console.log("blogsData", blogsData);

  const  STORY_BODY = blogsData.map((blog, index) => {
    return { number: index+1, id: blog?.id,  title: blog.title, Category: blog.category, date: moment(blog.createdAt).format('Do MMMM YYYY')};
  });

  
  
  
  const tableRef = useRef(null);

 

  return (
    <DatatableWrapper
      body={STORY_BODY}
      headers={STORY_HEADERS}
      paginationOptionsProps={{
        initialState: {
          rowsPerPage: 10,
          options: [5, 10, 15, 20]
        }
      }}
    >
      <Row className="mb-4 p-2">
        <Col
          xs={12}
          lg={4}
          className="d-flex flex-col justify-content-end align-items-end"
        >
          <Filter />
        </Col>
        <Col
          xs={12}
          sm={6}
          lg={8}
          className="d-flex flex-col justify-content-lg-end align-items-end justify-content-sm-start mb-2 mb-sm-0"
        >
          <PaginationOptions />
        </Col>
        
      </Row>
      <Table className='text-center'>
        <TableHeader />
        <TableBody />
      </Table>
      <Row className="mt-4">
        <Col
          xs={12}
          sm={6}
          lg={4}
          className="w-100 d-flex flex-col justify-content-center "
        >
          <Pagination className="w-100"/>
        </Col>
        </Row>
    </DatatableWrapper>
  );
};

export {MyDataTable};
