import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import { getBlogById, getFilteredBlogs, getAllBlog } from '../features/blog/blogSlice';
import { getCategoryById, getAllCategory } from '../features/category/categorySlice';
import { getTagById, getAllTag } from '../features/tag/tagSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/fr';
import CardBlog from '../components/CardBlog';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



export const FilterPage = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const blogs = useSelector((state) => state.blog.filteredBlog);

    const [blogsData, setBlogsData] = useState([]);
    console.log('blogs', blogs);

    const categories = useSelector((state) => state.category.categories);
    const allTags = useSelector((state) => state.tag.tags);

    const location = useLocation();

    const filterOptions = location.state?.filterOptions;

    const [isLoading, setIsLoading] = useState(true);



    console.log('filterOptions', filterOptions);


    const filteredBlogs = useSelector((state) => state.blog.filteredBlog);
    const [filterOptions2, setFilterOptions] = useState({});
    console.log('filterOptions2', filterOptions2);


    useEffect(() => {
        setIsLoading(true);
        dispatch(getAllCategory());
        dispatch(getAllTag());
        dispatch(getFilteredBlogs({...filterOptions, ...filterOptions2 }));
        setIsLoading(false);

    }, [dispatch]);

   

    useEffect(() => {
        setIsLoading(true);
        const fetchBlogsData = async () => {
            if (blogs && blogs.length > 0) {
                const blogsData = await Promise.all(blogs.map(async (blog) => {
                    if (blog && blog.category) {
                        const category = await dispatch(getCategoryById(blog.category));
                        if (category && category.payload && category.payload.title) {
                            return { ...blog, category: category.payload.title };
                        }
                    }
                    return blog;
                }));
                setBlogsData(blogsData);
            }
        };
        fetchBlogsData();
        setIsLoading(false);
    }, [blogs, dispatch]);


    

    useEffect(() => {
        if (Object.keys(filterOptions2).length > 0) {
            dispatch(getFilteredBlogs({...filterOptions, ...filterOptions2 }));
        }
    }, [filterOptions2, navigate, dispatch]);
    
    const handleCategoryClick = (category) => {
        setFilterOptions({ category: category });
    };

    const handleTagClick = (tag) => {
        setFilterOptions({ tag: tag });
    };

    const [search, setSearch] = useState('');

    const handleSearch = (event) => {
        event.preventDefault();
        setFilterOptions({ name: search });
    };

    if (isLoading) {
        return (
            <div className="container">
                <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }
    



    return (
        <>
            <main id="main">
                {/* ======= Breadcrumbs ======= */}
                <div className="breadcrumbs d-flex align-items-center" style={{ backgroundImage: 'url("/assets/img/breadcrumb.jpg")', backgroundPositionY : "-580px" }}>
                    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
                        <h2>Blog</h2>
                        <ol>
                            <li><Link to="/">Accueil</Link></li>
                            <li>Blog</li>
                        </ol>
                    </div>
                </div>{/* End Breadcrumbs */}
                <div>
                    <section id="blog" className="blog">
                        <div className="container" data-aos="fade-up" data-aos-delay={100}>
                            <div className="row posts-list">
                                <div className="col-lg-8 filtered-card ">
                                {blogsData.map((blog, index) => {
                                return (
                                    <div className="col-xl-4 col-md-6">
                                        <div className="post-item position-relative h-100">
                                            <div className="post-img position-relative overflow-hidden" style={{ width: "285px", height: "183px" }}>
                                                <img src={blog.images[0].url} className="img-fluid" alt="" style={{ width: "285px", height: "183px" }} />
                                                <span className="post-date">{moment(blog.createdAt).format('Do MMMM YYYY')}</span>
                                            </div>
                                            <div className="post-content d-flex flex-column">
                                                <h3 className="post-title">{blog.title}</h3>
                                                <div className="meta d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            blog.author == "Admin" ?
                                                                (
                                                                    <>
                                                                        <i className="bi bi-person" />
                                                                        <span className="ps-2">SahelGéo</span>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <i className="bi bi-person" />
                                                                        <span className="ps-2">{blog.author}</span>
                                                                    </>
                                                                )
                                                        }
                                                    </div>
                                                    <span className="px-3 text-black-50">/</span>
                                                    <div className="d-flex align-items-center">
                                                        <i className="bi bi-folder2" /> <span className="ps-2">{blog.category}</span>
                                                    </div>
                                                </div>
                                                <hr />
                                                <Link to={`/blog-details/${blog._id}`} className="readmore stretched-link">
                                                    <span>Lire l'article</span><i className="bi bi-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            )}



                                    




                                </div>
                                <div className="col-lg-4">
                                    <div className="sidebar">
                                        <div className="sidebar-item search-form">
                                            <h3 className="sidebar-title">Rechercher</h3>
                                            <form onSubmit={handleSearch} className="mt-3">
                                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                                                <button type="submit"><i className="bi bi-search" /></button>
                                            </form>
                                        </div>{/* End sidebar search formn*/}
                                        <div className="sidebar-item categories">
                                            <h3 className="sidebar-title">Catégories</h3>
                                            <ul className="mt-3">
                                                {
                                                    categories && categories.map((category, index) => (
                                                        <button className='btn' onClick={() => handleCategoryClick(category._id)}><li key={index}><a>{category.title}</a></li></button>
                                                    ))
                                                }
                                            </ul>
                                        </div>{/* End sidebar categories*/}
                                        <div className="sidebar-item recent-posts">
                                            <h3 className="sidebar-title">Articles récents</h3>
                                            {
                                                filteredBlogs && filteredBlogs.map((blog, index) => (
                                                    <div className="post-item" key={index}>
                                                        <img src={blog.images[0].url} alt="" />
                                                        <div>
                                                            <h4><a href="blog-details.html">{blog.title}</a></h4>
                                                            <time dateTime="2020-01-01">{moment(blog.createdAt).format('Do MMMM YYYY')}</time>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                           
                                        </div>{/* End sidebar recent posts*/}
                                        <div className="sidebar-item tags">
                                            <h3 className="sidebar-title">Tags</h3>
                                            <ul className="mt-3">
                                                {
                                                    allTags && allTags.map((tag, index) => (
                                                        <li key={index}><a onClick={() => handleTagClick(tag._id)}>{tag.title}</a></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>{/* End sidebar tags*/}
                                    </div>{/* End Blog Sidebar */}
                                </div>
                            </div>
                        </div>
                    </section>{/*End Blog Details Section-- >
*/}</div>


            </main>{/* End #main */}


        </>
    );
};


export default FilterPage;