import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { logoutUser } from '../../features/user/userSlice.js';
import { useDispatch } from 'react-redux';



const Header = () => {
    const  dispatch = useDispatch();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const email = JSON.parse(localStorage.getItem('user'))?.email;
    
    const navigate = useNavigate();

    const [importing, setImporting] = useState(false);

    const handleLogout = () => {
        dispatch(logoutUser()).then(() => {
            navigate('/admin/login');
        }
        );
    }

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/admin/login');
        }
    }
    , [navigate]);


    useEffect(() => {
        const load = async () => {
            import('./assets/style.css');
            import('./assets/admin.js')
            setImporting(true);
        };
        load();

      }, []);

     

      useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) {
            navigate('/admin/login');
        }
    }, [navigate]);

    if (!importing) {
        return <div id="preloader"></div>
      }

      
      const popupStyle = {
        position: 'absolute', 
        inset: '0px 0px auto auto', 
        margin: '0px', 
        transform: 'translate3d(-16px, 38px, 0px)'
    };

    return (
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <a href="index.html" className="admin-logo d-flex align-items-center">
                    <img src="/assets/img/logo.png" alt />
                </a>
                <i className="bi bi-list toggle-sidebar-btn" onClick={() => {
                    setSidebarOpen(!sidebarOpen);
                    if (!sidebarOpen) {
                        document.body.classList.add('toggle-sidebar');
                    } else {
                        document.body.classList.remove('toggle-sidebar');
                    }
                }} />
            </div>{/* End Logo */}
            <div className="search-bar">
                <form className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                    <button type="submit" title="Search"><i className="bi bi-search" /></button>
                </form>
            </div>{/* End Search Bar */}
            <nav className="header-nav ms-auto">
                <ul className="d-flex align-items-center">
                    <li className="nav-item d-block d-lg-none">
                        <a className="nav-link nav-icon search-bar-toggle " href="#">
                            <i className="bi bi-search" />
                        </a>
                    </li>{/* End Search Icon*/}
                    
                    <li className="nav-item pe-3">
                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" onClick={() => setShowPopup(!showPopup)}>
                            <img src="/assets/img/user-icon.png" alt="Profile" className="rounded-circle" />
                            <span className="d-none d-md-block dropdown-toggle ps-2" onClick={() => setShowPopup(true)}>Admin</span>
                    </a>{/* End Profile Iamge Icon */}
                    <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile ${showPopup ? 'show' : ''}`} style={showPopup ? popupStyle : {}}>
                        <li class="dropdown-header">
                        <h6>Admin</h6>
                        <span>{email}</span>
                        </li>
                        <li>
                        <hr class="dropdown-divider"/>
                        </li>
                        <li>
                        <a class="dropdown-item d-flex align-items-center" href="#">
                            <i class="bi bi-box-arrow-right"></i>
                            <span onClick={handleLogout}>Déconnexion</span>
                        </a>
                        </li>

                    </ul>
                        
                    </li>{/* End Profile Nav */}
                </ul>
            </nav>{/* End Icons Navigation */}
        </header>
    );
};

export default Header;