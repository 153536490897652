import axios from 'axios'
import { base_url, config, instance } from "../../utils/axiosconfig"

const createFaq = async (data) => {
    try {
        const response = await instance.post(`${base_url}faq`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getFaq = async () => {
    try {
        const response = await axios.get(`${base_url}faq`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const getFaqById = async (id) => {
    try {
        const response = await axios.get(`${base_url}faq/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const updateFaq = async (data) => {
    try {
        const response = await instance.put(`${base_url}faq/${data.id}`, data, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

const deleteFaq = async (id) => {
    try {
        const response = await instance.delete(`${base_url}faq/${id}`, config)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export const faqService = {
    getFaq,
    getFaqById,
    createFaq,
    updateFaq,
    deleteFaq
}
